<div class=" show" *ngIf="isMobile">
  <div style="text-align: center;">
    <p style="font-size: 110%;">Matches in LIVE streaming</p>
    <p> {{videoData.multi_channel_count}} units being distributed &nbsp;&nbsp;&nbsp;&nbsp;
      <span class="text-right" style="font-size: 18px;" (click)="updateEvents()">
        <i class="fa fa-refresh"></i>
      </span>
    </p>
  </div>
  <ng-container *ngIf="videoData.has_multi_channel">
    <div class="container1">
      <div class="row">
        <div [ngStyle]="{'cursor': !data.is_stop ? 'pointer' : 'default'}" *ngFor="let data of multiLiveEvents"
          (click)="changeStream(data)" [class]="getColumnClass()" style="margin-bottom: 5px;"
          [ngClass]="{'mobile-active': current_machine_id == data.machine_id}">

          <div *ngIf="!data.is_stop">
            <img [src]="data.thumbnail "  [ngClass]="{'mobile-active-class': current_machine_id == data.machine_id}"
              [alt]="data.title" class="img-fluid responsive-img" />

            <p class="font-weight-bold mb-2 mt-2 text-center" style="overflow-wrap: break-word;"
              *ngIf="data.machine_id">
              {{ controlLength(data.cards) }}
            </p>
            <p class="font-weight-bold mb-2 mt-2 text-center" *ngIf="!data.machine_id">
              Main Channel
            </p>
            <p class="mb-2">{{ data.date }}</p>
          </div>
          <div *ngIf="data.is_stop" class="data-container"
            style=" background-color: #ddd; color: #000; display: flex; justify-content: center; align-items: center;">
            <p style="font-size: 19px;">{{data.game_round}}</p>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div class="show" style="overflow-x: hidden; max-height: 590px;" *ngIf="!isMobile">
  <div style="display: flex; justify-content: space-between; align-items: center;">
    <h2 style="padding-left: 29px;">
      MultiLive &#946;
    </h2>
    <h2>
      <button class="text-right" (click)="updateEvents()" style="border: none; background: none; "><i
          class="fa fa-refresh"></i></button>
    </h2>
  </div>


  <ng-container *ngIf="videoData.has_multi_channel">
    <ng-container *ngIf="!isMobile">
      <div [ngStyle]="{'cursor': !data.is_stop ? 'pointer' : 'default'}" style="cursor: pointer" [ngClass]="{
        'active': current_machine_id == data.machine_id
      }" *ngFor="let data of multiLiveEvents" (click)="changeStream(data)" class="container">
        <ng-container>
          <div class="child1">
            <span *ngIf="current_machine_id == data.machine_id" style="font-size: 21px;  color: red;"><i
                class="fa fa-play"></i></span>
          </div>
          <div class="child2">
            <img *ngIf="!data.is_stop" [src]="data.thumbnail" [ngClass]="{
                'active-class': current_machine_id == data.machine_id
              }" [alt]="data.title" />
            <div *ngIf="data.is_stop"
              style="height: 80px; background-color: #ddd; color: #000; display: flex; justify-content: center; align-items: center;">
              <p style="font-size: 19px;">{{data.game_round}}</p>
            </div>
          </div>
          <div class="child3">
            <ng-container *ngIf="!data.is_stop">
              <p style="font-weight: 500; margin:0;" *ngIf="data.machine_id==0">
                {{ data.title }}
              </p>
              <p style="font-weight: 500; margin:0;" *ngIf="data.cards">
                {{ controlLength(data.cards) }}
              </p>
              <p style="font-weight: 500; margin:0;" *ngIf="!data.cards"></p>
              <p> {{ data.start_at | date:'yyyy-MM-dd' }}</p>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>
