import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-player-section',
    templateUrl: './player-section.component.html',
    styleUrls: ['./player-section.component.css'],
    standalone: false
})
export class PlayerSectionComponent {

  @Input() videoData: any;
  @Input() isMobile: boolean = false;
  @Input() multiLiveEvents: any[];
  @Input() current_machine_id: string = '';
  @Output() fetchData = new EventEmitter<void>();
  @Output() changeStream = new EventEmitter<any>();

  reloadStream(): void {
    this.fetchData.emit();
  }

  onChangeStream(event: any): void {
    this.changeStream.emit(event);
  }

}
