import {
  AfterViewInit,
  Component,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { AuthServiceService } from 'src/app/auth/service/auth-service.service';
import { LanguageService } from 'src/app/services/language.service';
import { SidebarService } from 'src/app/services/sidebarservice/sidebar.service';
import { DataShareService } from 'src/app/utilitis/services/data-share-services';
declare var $: any;
@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css'],
    standalone: false
})
export class SidebarComponent implements OnInit, AfterViewInit {
  private ngUnsubscribe: Subject<any> = new Subject();
  open: any;
  user: any = '';
  public menuList: any = {};
  public isMobile: any;
  public sidebar: any = true;
  public siteLink: any = 'https://www.dartslive.com/';
  constructor(
    public translate: TranslateService,
    private language: LanguageService,
    private datashareService: DataShareService,
    private sidebarService: SidebarService,
    public authService: AuthServiceService,
    private route: ActivatedRoute
  ) {

  }

  ngOnInit(): void {
    this.datashareService.sidebarStatus.subscribe((res: any) => {
      this.open = res;
    });
    this.user = localStorage.getItem('a_token');
    this.getCategoriesList();
    if (window.innerWidth <= 1024) {
      this.isMobile = true;
      this.datashareService.changeSidebarStatus(false);
    } else {
      this.isMobile = false;
      this.datashareService.changeSidebarStatus(true);
    }

    if (window.navigator.platform == 'iPad') {
      this.isMobile = true;
      this.datashareService.changeSidebarStatus(false);

    }

    this.datashareService.currentMessage.subscribe((msg) => (this.getCategoriesList()));
  }

  ngAfterViewInit() { }

  onClose() {
    this.datashareService.changeSidebarStatus(false);
  }

  onOverlayclick() {
    this.datashareService.changeSidebarStatus(false);
  }

  getCategoriesList() {
    this.sidebarService
      .getCategoriesList()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.menuList = res.data;
        if (this.menuList.length <= 0) {
          this.datashareService.changetoggleMenuStatus(false);
        }
      });
  }

  openLink() {
    this.siteLink = 'https://www.dartslive.com/'
    if (this.language.currentLang == 'jp') {
      this.siteLink = 'https://www.dartslive.com/jp'
    }
    window.open(this.siteLink)
  }
}
