<app-header></app-header>


<div class="wrapper">
  <div class="sidebar-main">
    <app-sidebar></app-sidebar>
  </div>
  <div class="content">
    <ng-container *ngIf="showButton">
      <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="staticBackdropLabel">Notification</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <button (click)="requestPermission()" type="button" class="btn btn-primary">Subscribe</button>
              <!-- <button type="button" class="btn btn-default" data-bs-dismiss="modal">Cancel</button> -->
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <router-outlet (activate)="onActivate($event)"></router-outlet>
    <app-footer></app-footer>
  </div>
</div>