import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class LanguageParamInterceptor implements HttpInterceptor {

    constructor( ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let ln = localStorage.getItem('lang') ?? environment.defaultLang
        const clone = request.clone({
            params: request.params.append('ln', ln)
        })
        return next.handle(clone)
    }
}
