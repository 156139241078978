import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
    selector: 'app-login-modal',
    templateUrl: './login-modal.component.html',
    styleUrls: ['./login-modal.component.css'],
    standalone: false
})
export class LoginModalComponent implements OnInit {
  @Input() videoData: any = {};
  public lang: any = '';
  siteLink = ''
  constructor(
    public translate: TranslateService,
    public langSerivce: LanguageService,
  ) { }

  openLink() {
    this.siteLink = 'https://www.dartslive.com/regist/?hunt=SdMNmCVyL9I57i92DJOu'
    if (this.langSerivce.currentLang == 'jp') {
      this.siteLink = 'https://www.dartslive.com/regist/dartslivetv/ja/?hunt=SdMNmCVyL9I57i92DJOu'
    }
    window.open(this.siteLink)
  }

  ngOnInit(): void {
  }

}
