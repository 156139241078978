import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {
  @Input() videoData: any = {};
  @Input() current_machine_id: any;
  @Input() multiLiveEvents: any;
  @Input() isOpenTab: any;
  isMobile = (window as any).isMobileLayout();
  @Output() childEvent = new EventEmitter();

  constructor() { }

  ngOnInit(): void {

  }

  controlLength(data: any) {
    if (data.length > 15) {
      data = data.substring(0, 15) + '...';
    }
    return data;
  }

  changeStream(data: any) {
    if (!data.is_stop)
      this.childEvent.emit(data);
  }


  updateEvents() {
    this.childEvent.emit({ update: true });
  }


}
