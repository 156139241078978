<div class="row" [ngStyle]="!isMobile ? {'padding': '0 25px'} : {}">
  <div class="col-sm-12 col-12">
    <div *ngIf="!error" class="player-section1 py-2">
      <div class="row" style="height: auto;">
        <div [class]="resClass">
          <div class="embed-youtube" *ngIf="videoData.url_type == 'youtube'">
            <div *ngIf="user.isLoggedIn() || restrict">
              <iframe style="width: 100%;aspect-ratio: 16 / 9;" allow="autoplay" allow="fullscreen"
                [src]="youtubestream_url">
              </iframe>
            </div>
            <div *ngIf="!user.isLoggedIn() || restrict" class="vid-box text-center">
              <img [src]="videoData.cover_image" />
              <div class="vid-overlay" *ngIf="!restrict">
                <div class="login-modal text-center">
                  <p>{{ "login.loginModal" | translate }}</p>
                  <div class="login">
                    <button [routerLink]="['/auth/login']" type="button" class="btn btn-primary">
                      {{ "login.loginButton" | translate }}
                    </button>
                  </div>
                  <div class="forgetpassword">
                    <a *ngIf="lang=='jp'" href="https://card.dartslive.com/entry/remind_password_mail.jsp" target="_blank">
                     {{ "login.forgetpassword" | translate }}</a>
                    <a *ngIf="lang=='en'" href="https://card.dartslive.com/entry/remind_password_mail.jsp"
                      target="_blank">
                      {{ "login.forgetpassword" | translate }}</a>
                  </div>
                  <div class="register" (click)="openLink()">
                    <a target="_blank">
                      <button type="button" class="btn btn-primary">
                        {{ "login.registerButton" | translate }}
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="video" *ngIf="videoData.url_type != 'youtube'">
            <div id="player" *ngIf="user.isLoggedIn() || restrict"></div>

            <div *ngIf="!user.isLoggedIn() || restrict" class="vid-box text-center">
              <img [src]="videoData.cover_image" />

              <!-- for not login condition -->

              <div class="vid-overlay" *ngIf="!restrict">
                <div class="login-modal text-center">
                  <p>{{ "login.loginModal" | translate }}</p>
                  <div class="login">
                    <button [routerLink]="['/auth/login']" type="button" class="btn btn-primary">
                      {{ "login.loginButton" | translate }}
                    </button>
                  </div>
                  <div class="forgetpassword">
                    <a *ngIf="lang=='jp'" href="https://card.dartslive.com/entry/remind_password_mail.jsp" target="_blank">
                      {{ "login.forgetpassword" | translate }}</a>
                    <a *ngIf="lang=='en'" href="https://card.dartslive.com/entry/remind_password_mail.jsp"
                      target="_blank">
                      {{ "login.forgetpassword" | translate }}</a>
                  </div>
                  <div class="register" (click)="openLink()">
                    <a target="_blank">
                      <button type="button" class="btn btn-primary">
                        {{ "login.registerButton" | translate }}
                      </button>
                    </a>
                  </div>
                </div>
              </div>

              <!-- for standard condition -->

              <div class="vid-overlay" *ngIf="restrict">
                <div *ngIf="videoData.purchase_type == 3" class="box-item">
                  <p>
                    {{ "login.standardModal" | translate }}
                  </p>
                  <div class="standard">
                    <a href="https://www.dartslive.com/guide/plan/" target="_blank">
                      <button type="button" class="btn">
                        {{ "login.standardButton" | translate }}
                      </button>
                    </a>
                  </div>
                </div>

                <!-- for premiun+ condition -->

                <div *ngIf="videoData.purchase_type == 4" class="box-item">
                  <p>
                    {{ "login.premiumModal" | translate }}
                  </p>
                  <div class="premiun">
                    <a href="https://www.dartslive.com/guide/premiumplus/" target="_blank">
                      <button type="button" class="btn">
                        {{ "login.premiumButton" | translate }}
                      </button>
                    </a>
                  </div>
                </div>

                <!-- for trail condition -->
              </div>
            </div>
          </div>
          <div class="player-wrapper">
            <div class="tag-wrapper">
              <div *ngIf="videoData.show_tag" class="ctag">
                <img src="{{ videoData.show_tag }}" alt="tags" />
              </div>
              <div *ngIf="videoData.ppv_tag" class="live-tag">
                <img src="{{ videoData.ppv_tag }}" alt="tags" />
              </div>
            </div>
            <div class="title">
              <div>
                <p>{{ videoData.title }}</p>
              </div>
            </div>

            <div class="description" id="description">
              <!-- <h2 >Overview</h2> -->
              <div >
                <p>{{ videoData.category.title_en }}</p>
              </div>
              <div  [innerHTML]="videoData.description"></div>
            </div>
          </div>

        </div>
        <div *ngIf="!isMobile" class="col-4"
        >
          <app-category *ngIf="videoData?.category?.slug" [category]="videoData.category"></app-category>
        </div>
      </div>




      <div class="langbuttons">
        <button (click)="back()" type="submit" class="btn">
          <a><span class="material-icons"> chevron_left </span>
            {{ "button.return" | translate }}</a>
        </button>
      </div>

      <div *ngIf="error" class="error">
        <p class="text-center">Something went wrong !!!</p>
        <div class="langbuttons">
          <button (click)="back()" type="submit" class="btn">
            <a><span class="material-icons"> chevron_left </span>
              {{ "button.return" | translate }}</a>
          </button>
        </div>
      </div>

    </div>
  </div>
</div>
