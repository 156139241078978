import { Component, Input, OnInit } from '@angular/core';
import { ChannelService } from 'src/app/services/channelservice/channel.service';

@Component({
    selector: 'app-category',
    templateUrl: './category.component.html',
    styleUrls: ['./category.component.css'],
    standalone: false
})
export class CategoryComponent implements OnInit {
  meta: any = {
    current_page: 1,
    last_page: 1,
  };
  @Input() category: any;
  loading = true;
  videos: any = [];
  constructor(private channelSevice: ChannelService) { }

  ngOnInit(): void {
    console.log(this.category, "Category:")
    this.channelSevice
      .getChannelByPaginateV2(this.category.slug, this.meta.current_page, 40)
      .subscribe(data => {
        this.loading = false
        this.videos = data['data']['videos'];
      })
  }

}
