import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpRequest, HttpInterceptor } from '@angular/common/http';
import { BehaviorSubject, Observable, of, TimeoutError } from 'rxjs';

import { AuthServiceService } from 'src/app/auth/service/auth-service.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  token: string = '';

  constructor(private auth: AuthServiceService) {
    
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = request.clone({
      
    });
    if(this.auth.getStorageToken()){
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.auth.getStorageToken()}`,
        },
      });
    }
    return next.handle(request);
  }
  
}
