import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { ChannelService } from 'src/app/services/channelservice/channel.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, takeLast, takeUntil } from 'rxjs/operators';
import { LanguageService } from 'src/app/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
declare var $: any;
@Component({
    selector: 'app-archives',
    templateUrl: './archives.component.html',
    styleUrls: ['./archives.component.css'],
    standalone: false
})
export class ArchivesComponent implements OnInit, AfterViewInit {
  public archiveData$: Observable<any> = of({});
  page: any = 1;
  data: any;
  meta: any = {
    current_page: 1,
    last_page: 1,
  };
  public channelData: any = [];
  public errorObject: any = null;
  public loading: any;
  public lang: any = '';
  public error: any = false;
  public total_pages: any = 0;
  nextPage: any;
  currentPosition = window.innerHeight;
  constructor(
    private channelService: ChannelService,
    public translate: TranslateService,
    private langSerivce: LanguageService,
    private titleService: Title
  ) {
    this.lang = this.langSerivce.getLang();
    translate.setDefaultLang(this.lang);
  }

  @HostListener('window:scroll', ['$event.target']) // for window scroll events
  scroll(e: any) {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
      if (this.meta.current_page < this.total_pages) {
        this.meta.current_page++;
        this.onloadMore();
      } else {
        this.loading = false;
      }
    }
  }

  ngOnInit(): void {
    this.translate.get('archive.title').subscribe((data) => {
      this.titleService.setTitle(data);
    });
    // this.titleService.setTitle(this.translate.instant('archive.title'));
    this.onloadMore();
  }
  ngAfterViewInit(): void {}
  onloadMore() {
    this.loading = false;
    this.channelService
      .getArchives(this.meta.current_page)
      .subscribe((res: any) => {
        if (res.meta.total == 0) {
          this.error = true;
          this.loading = false;
        } else {
          this.error = false;
          this.loading = false;
          this.meta = res.meta;

          this.nextPage = res.links.next;
          if (this.channelData.length) {
            res.data.forEach((element: any) => {
              this.channelData.push(element);
            });
          } else {
            this.channelData = res.data;
            this.total_pages = res.meta.last_page;
          }
        }
      });
  }
}
