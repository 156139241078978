<div *ngIf="loading" class="col-12 text-center">
  <img src="../../../../assets/loader/loader.gif" alt="loader" />
</div>

<div class="channel-section">
  <div class="row" style="">
  <div *ngIf="!error">
    <div *ngIf="!loading" class="archive" id="archivesection">
      <div class="row">
        <div class="channel-title">
          <h1>{{ "sidebar.archives" | translate }}</h1>
        </div>
        <div *ngFor="let item of channelData" class="col-lg-3 col-md-6 col-sm-12 list" app-item [item]="item"
          [lang]="lang" [link]="'watch'"></div>
      </div>
    </div>
  </div>
  <div class="error" *ngIf="error">
    <p class="text-center">No Videos Avaialble !!</p>
    <div class="langbuttons">
      <button [routerLink]="['/']" type="submit" class="btn">
        <a><span class="material-icons"> chevron_left </span> 戻る</a>
      </button>
    </div>
  </div>
</div>
</div>

