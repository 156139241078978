import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { DataShareService } from 'src/app/utilitis/services/data-share-services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AuthServiceService } from 'src/app/auth/service/auth-service.service';
import { SearchService } from 'src/app/services/search.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
import { NavigationService } from 'src/app/utilitis/services/navigation.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  private ngUnsubscribe: Subject<any> = new Subject();
  mySubscription: any;

  @ViewChild('profiledropdown', { static: false }) dropdownElement: ElementRef;
  elem: any = document.getElementById('input');
  open: boolean = false;
  public exampleData: any;
  profile: boolean = false;
  searchbar: any = false;
  languageState: any = false;
  selectedLanguage: any = '';
  searchForm!: FormGroup;
  searchInput: any;
  searchView: any = false;
  user: any;
  topSearchList: any = [];
  recentsearchList: any = [];
  toggleMenustatus: any = true;
  constructor(
    private datashareService: DataShareService,
    private formbuider: FormBuilder,
    public translate: TranslateService,
    private langSerivce: LanguageService,
    private searchService: SearchService,
    public authService: AuthServiceService,
    private langService: LanguageService,
    private route: Router,
    private navigation: NavigationService
  ) {
    this.selectedLanguage = this.langService.getLang();

    this.route.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.mySubscription = this.route.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.route.navigated = false;
      }
    });
  }

  handleProfile(event: any) {
    this.profile = !this.profile;
  }

  ngOnInit(): void {
    this.searchForm = this.formbuider.group({
      search: ['', Validators.required],
    });
    this.datashareService.sidebarStatus.subscribe((res: any) => {
      this.open = !res;
    });
    this.datashareService.profiledisabled.subscribe((res: any) => {
      this.profile = res;
    });
    this.datashareService.toggleMenu.subscribe((res: any) => {
      this.toggleMenustatus = res;
    });
  }

  ontop() {
    if (this.showOnTop()) window.scrollTo(0, 0);
  }

  showOnTop() {
    if (this.route.url == '/') {
      return true;
    }
    return false;
  }

  onLogin() {
    this.route.navigate(['/auth/login']);
  }

  onMypage() {
    this.route.navigate(['/auth/user-profile']);
  }

  clickedOutside() {
    this.profile = false;
    this.languageState = false;
  }

  clickedOutsidesearch() {
    this.searchbar = false;
  }
  chooseText(text: any) {
    this.searchForm.patchValue({ search: text });
    this.searchbar = false;
    this.search();
  }

  chooseText2(text: any) {
    this.searchForm.patchValue({ search: text });
    this.searchView = true;
  }

  search() {
    if (this.searchForm.invalid) {
      return;
    }
    const formValue = this.searchForm.value;
    this.searchInput = formValue.search;
    // this.searchService.recordsearchHistory(this.searchInput).subscribe();
    this.searchView = false;
    this.route.navigate(['/search'], {
      queryParams: { search: this.searchInput },
    });
    this.datashareService.search(this.searchInput)
  }


  handleClick() {
    this.datashareService.changeSidebarStatus(this.open);
  }

  handleSearch() {
    this.searchForm.reset();
    this.searchView = true;
    this.searchService
      .getsearchHistory()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        (this.topSearchList = res.data.top_searches),
          (this.recentsearchList = res.data.recent_searches);
      });
  }

  searchClose() {
    this.searchView = false;
  }

  handleLanguage() {
    this.languageState = !this.languageState;
  }

  changeLanguage(lang: string) {
    this.translate.use(lang);
    this.langSerivce.setLang(lang);
    this.selectedLanguage = lang;
    this.profile = false;
    let currentUrl = this.route.url;

    // this.route.navigateByUrl('/', {skipLocationChange: true}).then(() => {
    this.route.navigateByUrl(currentUrl);
    // });
  }

  onLogout() {
    this.navigation.pop();
    this.navigation.pop();
    this.authService.logout();
    this.route.navigate(['/auth/login']);
  }

  toggleMenu() {
    if (this.searchForm.value['search'].length > 0) {
      this.searchbar = true;
    } else {
      this.searchbar = false;
    }
  }

  showMenu() {
    this.searchService
      .getsearchHistory()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        (this.topSearchList = res.data.top_searches),
          (this.recentsearchList = res.data.recent_searches);
      });
  }

  deleteHistory(id: any) {
    this.searchForm.patchValue({ search: '' });
    this.searchService
      .deletesearchHistory(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        if (res.message) {
          this.searchService
            .getsearchHistory()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res: any) => {
              (this.topSearchList = res.data.top_searches),
                (this.recentsearchList = res.data.recent_searches);
            });
        }
      });
  }
}
