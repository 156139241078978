import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
    selector: 'app-privacy-policy',
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['./privacy-policy.component.css'],
    standalone: false
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(
    private titleService: Title,
    public translate: TranslateService,
    public langSerivce: LanguageService
  ) { }

  ngOnInit(): void {
    this.translate.get('privacy.title').subscribe(data=>{
      this.titleService.setTitle(data);
    })
    // this.titleService.setTitle(this.translate.instant('privacy.title'));
  }

}
