import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthServiceService } from 'src/app/auth/service/auth-service.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private baseUrl = environment.apiUrl;
  static accessToken = '';
  refresh = false;

  constructor(private http: HttpClient, private service: AuthServiceService, private router: Router) { }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const req = request.clone({});
    if (this.service.getStorageToken()) {
      const req = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.service.getStorageToken()}`,
        },
      });
    }

    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          this.refresh = true;

          return this.http.get(this.baseUrl + 'refresh/token', {}).pipe(
            switchMap((res: any) => {
              this.service.storeToken(res.data.access_token);

              return next.handle(
                request.clone({
                  setHeaders: {
                    Authorization: `Bearer ${this.service.getStorageToken()}`,
                  },
                })
              );
            })
          );
        }
        if (err.status === 403) {
          localStorage.removeItem('token')
          this.router.navigate(['auth/login'])
        }
        this.refresh = false;
        return throwError(() => err);
      })
    );
  }
}
