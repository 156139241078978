import { AfterViewInit, Component, HostListener, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LanguageService } from './services/language.service';
import { DataShareService } from './utilitis/services/data-share-services';
import { Firestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage } from "firebase/messaging";
import { getToken } from "firebase/messaging";
import { Modal } from 'bootstrap'; // Import the Modal class

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterViewInit {
  status: any;
  item$: Observable<any>;
  isSubscribed = false;

  firestore: Firestore;
  mobilesidebar: any;
  title = 'DartsLivetv';
  public lang: any = '';
  constructor(
    private datashareService: DataShareService,
    private router: Router,
    private titleService: Title,
    private message: AngularFireMessaging,
    private meta: Meta,
    private langSerivce: LanguageService
  ) {
    this.datashareService.changeprofileStatus(false);
    this.lang = this.langSerivce.getLang();
    if (environment.production && environment.apiUrl == "https://api.live.dartslive.tv/api/v1/") {
      if (this.lang == 'en') {
        this.meta.addTags([
          {
            'data-n-head': 'description',
            name: 'description',
            content: 'Fascinating dart fans with high level matches',
          },
          {
            'data-n-head': 'og:description',
            name: 'og:description',
            content: 'Fascinating dart fans with high level matches',
          },
          {
            'data-n-head': 'ssr',
            name: 'format-detection',
            content: 'telephone=no',
          },
          {
            'data-n-head': 'ssr',
            name: 'format-detection',
            content: 'telephone=no',
          },
          {
            'data-n-head': 'ssr',
            name: 'keywords',
            content:
              'dartslive,ダーツライブ,DARTS,ダーツ,飛鏢,ダーツライブtv,dartslive tv,ダーツ 投げ方,ダーツ 試合',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'og:type',
            name: 'og:type',
            content: 'og:type',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'og:url',
            name: 'og:url',
            content: 'https://dartslive.tv/',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'og:site_name',
            name: 'og:site_name',
            content: 'DARTSLIVE TV - DARTSLIVE Official Video Site',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'og:i18n-og',
            name: 'og:locale',
            content: 'en_US',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'i18n-og-alt-ja-JP',
            name: 'og:locale:alternate',
            content: 'ja_JP',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'og:title',
            name: 'og:title',
            content: 'DARTSLIVE TV - DARTSLIVE Official Video Site',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'og:title',
            name: 'og:title',
            content: 'DARTSLIVE TV - DARTSLIVE Official Video Site',
          },
        ]);
      }
      if (this.lang == 'jp') {
        this.meta.addTags([
          {
            'data-n-head': 'description',
            name: 'description',
            content: '世界のダーツファンを魅了するハイレベルな試合を配信',
          },
          {
            'data-n-head': 'og:description',
            name: 'og:description',
            content: '世界のダーツファンを魅了するハイレベルな試合を配信',
          },
          {
            'data-n-head': 'ssr',
            name: 'format-detection',
            content: 'telephone=no',
          },
          {
            'data-n-head': 'ssr',
            name: 'format-detection',
            content: 'telephone=no',
          },
          {
            'data-n-head': 'ssr',
            name: 'keywords',
            content:
              'dartslive,ダーツライブ,DARTS,ダーツ,飛鏢,ダーツライブtv,dartslive tv,ダーツ 投げ方,ダーツ 試合',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'og:type',
            name: 'og:type',
            content: 'og:type',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'og:url',
            name: 'og:url',
            content: 'https://dartslive.tv/',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'og:site_name',
            name: 'og:site_name',
            content:
              'ダーツライブTV - DARTSLIVE(ダーツライブ)公式ダーツ動画サイト',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'og:i18n-og',
            name: 'og:locale',
            content: 'ja_JP',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'i18n-og-alt-ja-JP',
            name: 'og:locale:alternate',
            content: 'ja_JP',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'og:title',
            name: 'og:title',
            content:
              'ダーツライブTV - DARTSLIVE(ダーツライブ)公式ダーツ動画サイト',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'og:title',
            name: 'og:title',
            content:
              'ダーツライブTV - DARTSLIVE(ダーツライブ)公式ダーツ動画サイト',
          },
        ]);
      }
    }
  }
  @HostListener('window:storage')
  onStorageChange() { }
  showButton: boolean = true;

  async ngOnInit(): Promise<void> {
    // this.requestPermission();
    this.titleService.setTitle(this.title);
    this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.datashareService.sidebarStatus.subscribe((res) => {
      this.status = res;
    });

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/firebase-messaging-sw.js')
        .then((registration) => {
          console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch((err) => {
          console.log('Service Worker registration failed:', err);
        });
    }
    // if(!(window as any).isAppleEnvironment()){
    this.requestPermission().then(data => console.log)
    // }
  }

  onActivate(event: any) {
    if (event.constructor.name == '/') {
      window.scrollTo(0, 0);
    }
  }

  async requestPermission() {
    const app = initializeApp(environment.firebase);
    const messaging = getMessaging(app);
    const currentToken = await getToken(messaging, { vapidKey: "BKBUMEPwELBwfUJ2_SKj7i-kPfcGC23bkkQuDjOtj3D0s-SJS7ltzSfRh_cTEL8WvdT6UlMXYv5JZF7ALbC6bkI" });
    if (!currentToken) {
      console.log('No registration token available. Request permission to generate one.');
      return;
    }
    console.log(currentToken)
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      localStorage.setItem('notification', 'access');
      console.log('Notification permission granted.');
      const response = await fetch(environment.apiUrl + 'iid/topics?token=' + currentToken, {
        method: 'POST'
      });
      if (response.status < 200 || response.status >= 400) {
        console.error('Error subscribing token to topic:', response.statusText);
        return;
      }
    } else {
      console.log('not permission granted.');
    }
    // $('#staticBackdrop').modal('hide');
  }

  ngAfterViewInit() {
    // if ('Notification' in window) {
    //   if(!(window as any).isAppleEnvironment()){
    //     return;
    //   }
    //   if (localStorage.getItem('notification') == 'access') return;
    //   const modalElement = document.getElementById('staticBackdrop');
    //   if (modalElement) {
    //     const modal = new Modal(modalElement, {
    //       backdrop: 'static',
    //       keyboard: false,
    //     });
    //     modal.show();
    //   }
    // }
  }

}
