import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private history: string[] = [];

  constructor(private router: Router, private location: Location) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  back(): void {
    const currentDomain = window.location.hostname;
    const previousURL = this.history.length > 0 ? this.history[this.history.length - 1] : null;
    let previousDomain: string | null = null;
    if (previousURL) {
      try {
        previousDomain = new URL(previousURL, window.location.origin).hostname;
      } catch (error) {
        console.warn('Invalid previous URL:', previousURL);
        previousDomain = null;
      }
    }
    if (!previousDomain || currentDomain !== previousDomain) {
      this.router.navigateByUrl('/');
    } else {
      this.history.pop();
      if (this.history.length > 0) {
        this.location.back();
      } else {
        this.router.navigateByUrl('/');
      }
    }
  }

  pop() {
    this.history = [];
  }


}

