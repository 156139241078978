<div class="row">
  <div class="col-sm-10 offset-sm-1 col-12" *ngIf="!isMobile">
    <div *ngIf="!error" class="player-section1 py-1">
      <div class="row">
        <div class="col-md-8">
          <div class="embed-youtube" *ngIf="videoData.url_type == 'youtube'">
            <div *ngIf="user.isLoggedIn(); else notUserLogin">
              <iframe allow="fullscreen" allowfullscreen style="width: 100%; aspect-ratio: 16 / 9" allow="autoplay"
                [src]="youtubestream_url">
              </iframe>
            </div>
          </div>
          <div class="video" *ngIf="videoData.url_type != 'youtube'">
            <ng-container>
              <div class="vid-box text-center" *ngIf="diff >= 1 && !videoData.restrict">
                <img [src]="videoData.cover_image" />
                <div class="vid-overlay">
                  <div class="timmer">
                    <div style="margin-right: 15px">
                      <!-- <span class="material-icons"> live_tv </span> -->
                      <img src="../../../../assets/live.png" alt="live-icon" />
                    </div>
                    <div style="text-align: left">
                      <p>Waiting for DARTSLIVE TV</p>
                      <p>{{ videoData.date2 | date : "yyyy-MM-dd" }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="vid-box text-center" *ngIf="diff <= 0 && videoData.is_offline && !videoData.restrict">
                <img [src]="videoData.cover_image" />
                <div class="vid-overlay">
                  <div class="timmererror">
                    <div style="margin-right: 15px">
                      <p>Reload</p>
                    </div>
                    <div>
                      <span class="material-icons" (click)="fetchDataWithStream()">
                        restore
                      </span>
                    </div>
                  </div>
                  <div class="timmer">
                    <div style="margin-right: 15px">
                      <!-- <span class="material-icons"> live_tv </span> -->
                      <img src="../../../../assets/live.png" alt="live-icon" />
                    </div>
                    <div style="text-align: left">
                      <p>Waiting for DARTSLIVE TV</p>
                      <p>{{ videoData.date2 | date : "yyyy-MM-dd" }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="!videoData.is_offline && user.isLoggedIn()">
                <div id="player" *ngIf="
                              user.isLoggedIn() || !videoData.restrict;
                              else notUserLogin
                            "></div>
              </div>

              <div *ngIf="!user.isLoggedIn() || videoData.restrict" class="vid-box text-center">
                <img [src]="videoData.cover_image" />
                <!-- for not login condition -->
                <div class="vid-overlay" *ngIf="!videoData.restrict">
                  <div class="login-modal text-center">
                    <p>{{ "login.loginModal" | translate }}</p>
                    <div class="login">
                      <button [routerLink]="['/auth/login']" type="button" class="btn btn-primary">
                        {{ "login.loginButton" | translate }}
                      </button>
                    </div>
                    <div class="forgetpassword">
                      <a *ngIf="lang == 'jp'" href="https://dlapp.zendesk.com/hc/ja/articles/360057861933"
                        target="_blank">
                        > &nbsp;{{ "login.forgetpassword" | translate }}</a>
                      <a *ngIf="lang == 'en'" href="https://dlapp.zendesk.com/hc/en-us/articles/360057861933"
                        target="_blank">
                        > &nbsp;{{ "login.forgetpassword" | translate }}</a>
                    </div>
                    <div class="register" (click)="openLink()">
                      <a target="_blank">
                        <button type="button" class="btn btn-primary">
                          {{ "login.registerButton" | translate }}
                        </button>
                      </a>
                    </div>
                  </div>
                </div>

                <!-- for standard condition -->

                <div class="vid-overlay" *ngIf="videoData.restrict">
                  <div *ngIf="videoData.purchase_type == 3" class="box-item">
                    <p>
                      {{ "login.standardModal" | translate }}
                    </p>
                    <div class="standard">
                      <button type="button" class="btn">
                        <a href="https://www.dartslive.com/guide/plan/" target="_blank">
                          {{ "login.standardButton" | translate }}</a>
                      </button>
                    </div>
                  </div>

                  <!-- for premiun condition -->

                  <div *ngIf="videoData.purchase_type == 4" class="box-item">
                    <p>
                      {{ "login.premiumModal" | translate }}
                    </p>
                    <div class="premiun">
                      <button type="button" class="btn">
                        <a href="https://www.dartslive.com/guide/plan/" target="_blank">
                          {{ "login.premiumButton" | translate }}
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
                <!-- <div *ngIf="videoData.is_archive == 0" class="tag">
                            <img src="../../../../assets/sidebar/livetag.png" alt="" />
                          </div> -->
              </div>
            </ng-container>
          </div>
          <div class="player-wrapper">
            <div class="tag-wrapper">
              <div *ngIf="videoData.show_tag" class="ctag">
                <img src="{{ videoData.show_tag }}" alt="tags" />
              </div>
              <div style="margin-right: 15px" *ngIf="videoData.ppv_tag" class="live-tag">
                <img src="{{ videoData.ppv_tag }}" alt="tags" />
              </div>

              <div class="live-tag">
                <img src="../../../../assets/sidebar/livetag.png" alt="" />
              </div>
            </div>
            <div class="title">
              <p>{{ videoData.title }}</p>
            </div>

          </div>
        </div>
        <div class="col-md-4"
          style="border-right: 1px solid #ccc;border-left: 1px solid #ccc;border-top: 1px solid #ccc;">
          <app-events [videoData]="videoData" [multiLiveEvents]="multiLiveEvents" [isOpenTab]="isOpenTab"
            [current_machine_id]="current_machine_id" (childEvent)="changeStream($event)"></app-events>
        </div>
        <hr />
      </div>

      <div class="row">
        <h2>Overview</h2>
        <div>
          <p>{{ videoData.category.title_en }}</p>
        </div>
        <div class="description" id="description">
          <div class="link" [innerHTML]="videoData.description"></div>
        </div>
      </div>

      <hr />
      <div class="langbuttons">
        <button (click)="back()" type="submit" class="btn">
          <a><span class="material-icons"> chevron_left </span>
            {{ "button.return" | translate }}</a>
        </button>
      </div>
    </div>
    <div *ngIf="error" class="error">
      <p class="text-center">Something went wrong !!!</p>
      <div class="langbuttons">
        <button (click)="back()" type="submit" class="btn">
          <a><span class="material-icons"> chevron_left </span>
            {{ "button.return" | translate }}</a>
        </button>
      </div>
    </div>
  </div>

  <div class="col-sm-10 offset-sm-1 col-12" *ngIf="isMobile">
    <div *ngIf="!error" class="player-section1 py-1">
      <div class="row">
        <div class="col-md-12">
          <div class="embed-youtube" *ngIf="videoData.url_type == 'youtube'">
            <div *ngIf="user.isLoggedIn(); else notUserLogin">
              <iframe allow="fullscreen" allowfullscreen style="width: 100%; aspect-ratio: 16 / 9" allow="autoplay"
                [src]="youtubestream_url">
              </iframe>
            </div>
          </div>
          <div class="video" *ngIf="videoData.url_type != 'youtube'">
            <ng-container>
              <div class="vid-box text-center" *ngIf="diff >= 1 && !videoData.restrict">
                <img [src]="videoData.cover_image" />
                <div class="vid-overlay">
                  <div class="timmer">
                    <div style="margin-right: 15px">
                      <!-- <span class="material-icons"> live_tv </span> -->
                      <img src="../../../../assets/live.png" alt="live-icon" />
                    </div>
                    <div style="text-align: left">
                      <p>Waiting for DARTSLIVE TV</p>
                      <p>{{ videoData.date2 | date : "yyyy-MM-dd" }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="vid-box text-center"
                *ngIf="user.isLoggedIn() && (diff <= 0 && videoData.is_offline && !videoData.restrict)">
                <img [src]="videoData.cover_image" />
                <div class="vid-overlay">
                  <div class="timmererror">
                    <div style="margin-right: 15px">
                      <p>Reload</p>
                    </div>
                    <div>
                      <span class="material-icons" (click)="fetchDataWithStream()">
                        restore
                      </span>
                    </div>
                  </div>
                  <div class="timmer">
                    <div style="margin-right: 15px">
                      <!-- <span class="material-icons"> live_tv </span> -->
                      <img src="../../../../assets/live.png" alt="live-icon" />
                    </div>
                    <div style="text-align: left">
                      <p>Waiting for DARTSLIVE TV</p>
                      <p>{{ videoData.date2 | date : "yyyy-MM-dd" }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="!videoData.is_offline && user.isLoggedIn()">
                <div id="player" *ngIf="
                              user.isLoggedIn() || !videoData.restrict;
                              else notUserLogin
                            "></div>
              </div>

              <div *ngIf="!user.isLoggedIn() || videoData.restrict" class="vid-box text-center">
                <img [src]="videoData.cover_image" />
                <!-- for not login condition -->
                <div class="vid-overlay" *ngIf="!videoData.restrict">
                  <div class="login-modal text-center">
                    <p>{{ "login.loginModal" | translate }}</p>
                    <div class="login">
                      <button [routerLink]="['/auth/login']" type="button" class="btn btn-primary">
                        {{ "login.loginButton" | translate }}
                      </button>
                    </div>
                    <div class="forgetpassword">
                      <a *ngIf="lang == 'jp'" href="https://dlapp.zendesk.com/hc/ja/articles/360057861933"
                        target="_blank">
                        > &nbsp;{{ "login.forgetpassword" | translate }}</a>
                      <a *ngIf="lang == 'en'" href="https://dlapp.zendesk.com/hc/en-us/articles/360057861933"
                        target="_blank">
                        > &nbsp;{{ "login.forgetpassword" | translate }}</a>
                    </div>
                    <div class="register" (click)="openLink()">
                      <a target="_blank">
                        <button type="button" class="btn btn-primary">
                          {{ "login.registerButton" | translate }}
                        </button>
                      </a>
                    </div>
                  </div>
                </div>

                <!-- for standard condition -->

                <div class="vid-overlay" *ngIf="videoData.restrict">
                  <div *ngIf="videoData.purchase_type == 3" class="box-item">
                    <p>
                      {{ "login.standardModal" | translate }}
                    </p>
                    <div class="standard">
                      <button type="button" class="btn">
                        <a href="https://www.dartslive.com/guide/plan/" target="_blank">
                          {{ "login.standardButton" | translate }}</a>
                      </button>
                    </div>
                  </div>

                  <!-- for premiun condition -->

                  <div *ngIf="videoData.purchase_type == 4" class="box-item">
                    <p>
                      {{ "login.premiumModal" | translate }}
                    </p>
                    <div class="premiun">
                      <button type="button" class="btn">
                        <a href="https://www.dartslive.com/guide/plan/" target="_blank">
                          {{ "login.premiumButton" | translate }}
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
                <!-- <div *ngIf="videoData.is_archive == 0" class="tag">
                            <img src="../../../../assets/sidebar/livetag.png" alt="" />
                          </div> -->
              </div>
            </ng-container>
          </div>
          <div class="player-wrapper">
            <div class="tag-wrapper">
              <div *ngIf="videoData.show_tag" class="ctag">
                <img src="{{ videoData.show_tag }}" alt="tags" />
              </div>
              <div style="margin-right: 15px" *ngIf="videoData.ppv_tag" class="live-tag">
                <img src="{{ videoData.ppv_tag }}" alt="tags" />
              </div>

              <div class="live-tag">
                <img src="../../../../assets/sidebar/livetag.png" alt="" />
              </div>
            </div>
            <div class="title">
              <p>{{ videoData.title }}</p>
            </div>

          </div>

          <div class="player-wrapper">
            <ul class="nav program-tabs mb-3" role="tablist">
              <li class="nav-item">
                <a class="nav-link active" [class]="{ 'active': isopenLink === 'pills-chefsprogram-tab' }"
                  (click)="OpenLink('pills-chefsprogram-tab')" id="pills-chefsprogram-tab" data-toggle="pill" role="tab"
                  aria-controls="pills-chefsprogram" aria-selected="true">Overview</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [class]="{ 'active': isopenLink === 'pills-youth-tab' }"
                  (click)="OpenLink('pills-youth-tab')" id="pills-youth-tab" data-toggle="pill" role="tab"
                  aria-controls="pills-youth" aria-selected="true">Multi Live &Beta;</a>
              </li>
            </ul>

            <div class="tab-content" style="padding: 10px;">
              <div *ngIf="isopenLink === 'pills-chefsprogram-tab'" class="tab-pane fade show active"
                id="pills-chefsprogram">
                <div>
                  <p>{{ videoData.category.title_en }}</p>
                </div>
                <div class="description" id="description">
                  <div class="link" [innerHTML]="videoData.description"></div>
                </div>
              </div>
              <div *ngIf="isopenLink === 'pills-youth-tab'" class="tab-pane fade show active" id="pills-youth">
                <app-events [videoData]="videoData" [multiLiveEvents]="multiLiveEvents" [isOpenTab]="isOpenTab"
                  [current_machine_id]="current_machine_id" (childEvent)="changeStream($event)"></app-events>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


    <hr />
    <div class="langbuttons">
      <button (click)="back()" type="submit" class="btn">
        <a><span class="material-icons"> chevron_left </span>
          {{ "button.return" | translate }}</a>
      </button>
    </div>
  </div>
</div>

<ng-template #notUserLogin>
  <app-login-modal videoData="videoData" *ngIf="!user.isLoggedIn()"></app-login-modal>
</ng-template>