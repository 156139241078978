<div>
  <div *ngIf="alert" class="notification">
    <div class="warning">
      <span class="material-icons"> warning </span>
    </div>
    <div class="warning-text">
      <p [innerHTML]="alert"></p>
    </div>
  </div>

  <!-- banner section -->

  <div class="hero-slider" id="thumbnail-slider">
    <ngx-slick-carousel class="carousel" [config]="slideConfig" (init)="slickInit($event)">
      <div ngxSlickItem *ngFor="let data of bannerList" class="top-slider">
        <a [routerLink]="['/channel', data.slug]">
          <img src="{{ data.banner_img }}" alt="" class="homeimage" />
        </a>
      </div>
    </ngx-slick-carousel>
  </div>

  <!-- banner section ends here -->

  <div class="row">
    <div class="col-sm-12 col-12">
      <!-- for live static -->

      <div class="channels" id="channel-list" *ngIf="liveData.length">
        <div class="channel-name">
          <div style="display: flex; align-items: center">
            <div class="border-line"></div>
            <h3>{{ "home.live" | translate }}</h3>
          </div>
          <div [routerLink]="['/live']" class="seemore">
            <p>
              <a>{{ "home.see_more" | translate }}</a>
            </p>
            <span class="material-icons"> keyboard_arrow_right </span>
          </div>
        </div>
        <div class="channel-list">
          <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="channelslideConfig"
            (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
            (beforeChange)="beforeChange($event)">
            <div *ngFor="let item of liveData" ngxSlickItem class="slide" app-item [item]="item" [link]="'live-watch'"
              [lang]="lang"></div>
          </ngx-slick-carousel>
        </div>
      </div>


      <div class="channels" id="channel-list" *ngIf="multiLiveEvents.length">
        <div class="channel-name">
          <div style="display: flex; align-items: center">
            <div class="border-line"></div>
            <h3>{{ "home.multiLiveEvent" | translate }}</h3>
          </div>
          <div [routerLink]="['/multi-live-events']" class="seemore">
            <p>
              <a>{{ "home.see_more" | translate }}</a>
            </p>
            <span class="material-icons"> keyboard_arrow_right </span>
          </div>
        </div>
        <div class="channel-list">
          <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="channelslideConfig"
            (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
            (beforeChange)="beforeChange($event)">
            <div *ngFor="let item of multiLiveEvents" ngxSlickItem class="slide" app-item [item]="item" [link]="'live-watch'"
              [lang]="lang"></div>
          </ngx-slick-carousel>
        </div>
      </div>

      <!-- for archive static -->

      <div class="channels" id="channel-list" *ngIf="archiveData.length">
        <div class="channel-name">
          <div style="display: flex; align-items: center">
            <div class="border-line"></div>
            <h3>{{ "home.archives" | translate }}</h3>
          </div>
          <div [routerLink]="['/archives']" class="seemore">
            <p>
              <a>{{ "home.see_more" | translate }}</a>
            </p>
            <span class="material-icons"> keyboard_arrow_right </span>
          </div>
        </div>
        <div class="channel-list">
          <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="channelslideConfig"
            (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
            (beforeChange)="beforeChange($event)">
            <div *ngFor="let item of archiveData" ngxSlickItem class="slide" app-item [item]="item" [link]="'watch'"
              [lang]="lang"></div>
          </ngx-slick-carousel>
        </div>
      </div>
      <ng-container *ngFor="let data of data.data">
        <!-- for dynamic data -->
        <ng-container *ngIf="data.child.length>0">
          <div >
            <div class="channels" id="channel-list">
              <div class="channel-name">
                <div style="display: flex; align-items: center">
                  <div class="border-line"></div>
                  <h3>{{ data.title }}</h3>
                </div>
                <div [routerLink]="['/parent-channel/', data.id]" class="seemore">
                  <p>
                    <a>{{ "home.see_more" | translate }}</a>
                  </p>
                  <span class="material-icons"> keyboard_arrow_right </span>
                </div>
              </div>
              <div class="channel-list">
                <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="channelslideConfig"
                  (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
                  (beforeChange)="beforeChange($event)">

                  <!-- this change -->
                  <div *ngFor="let item of data.child" ngxSlickItem class="slide slide-container" app-parent-item
                    [item]="item" [link]="'watch'" [lang]="lang">
                  </div>


                </ngx-slick-carousel>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="data.videos.length>0">
          <div >
            <ng-container *ngIf="data.videos.length">
              <div class="channels" id="channel-list">
                <div class="channel-name">
                  <div style="display: flex; align-items: center">
                    <div class="border-line"></div>
                    <h3>{{ data.title }}</h3>
                  </div>
                  <div [routerLink]="['/channel/', data.slug]" class="seemore">
                    <p>
                      <a>{{ "home.see_more" | translate }}</a>
                    </p>
                    <span class="material-icons"> keyboard_arrow_right </span>
                  </div>
                </div>
                <div class="channel-list">
                  <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="channelslideConfig"
                    (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
                    (beforeChange)="beforeChange($event)">
                    <div *ngFor="let item of data.videos" ngxSlickItem class="slide" app-item [item]="item" [link]="item.type === 'live' ? 'live-watch' : 'watch'"
                      [lang]="lang"></div>
                  </ngx-slick-carousel>
                </div>
              </div>
          </ng-container>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="loading">
        <app-loader></app-loader>
      </ng-container>
    </div>
  </div>
</div>

<div class="showFooter" *ngIf="showFooter"
  style="color: #fff; position: fixed; bottom: 5px; z-index:9999; height: auto; width: 100%; margin: 0 auto; padding: 5px;">
  <div (click)="hideFooter()" style="flex-grow: 1; font-size: 10px; font-weight: bold; align-self: center; ">
    <p style="font-size: 10px; cursor: pointer;"><img style="width: 18px" src="../../../../assets/close.png" alt=""></p>
  </div>
  <div
    style="background: url('./assets/Banner_Design_layer.png'); background-repeat: no-repeat ; background-size: cover; padding: 8px; display: flex;  align-items: center;">
    <div style="flex-grow: 1; margin-right: 12px;">
      <img id="logo" src="../../../../assets/icon.png" style="margin-left:6px; height: 60px;" alt="logo" />
    </div>
    <div
      style="color: #fff; font-size: 20px; display: flex; align-items: center; flex-grow: 4; justify-content: center;">

      <div (click)="openLink()" style="flex-grow: 3; margin-right: 4px;">
        <a [href]="playStoreUrl" style="color: #fff !important" target="_new">
          <span style="line-height: 18px;" class="chakra-text css-zvlevn">{{ "footer.app_link" | translate }}</span>
        </a>

      </div>
      <div style="font-size: 20px; flex-grow: 1">
        <a [href]="playStoreUrl" style="color: #fff !important" target="_new"> <i class="fa fa-chevron-right"></i></a>
      </div>

    </div>
  </div>
</div>
