import { AfterViewChecked, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, Subject, takeUntil } from 'rxjs';
import { AuthServiceService } from 'src/app/auth/service/auth-service.service';
import { LanguageService } from 'src/app/services/language.service';
import { PlayerService } from 'src/app/services/playerservice/player.service';
import { NavigationService } from 'src/app/utilitis/services/navigation.service';
declare var YT: any;
declare var td: any;
declare var jwplayer: any;
declare var $: any;

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.css'],
  standalone: false
})
export class PlayerComponent implements OnInit, OnDestroy, AfterViewChecked {
  private ngUnsubscribe: Subject<any> = new Subject();
  @ViewChild('youtubeIframe', { static: false }) youtubeIframe!: ElementRef;

  public watch_id: any;
  public next_video_id: any = '';
  public video: any;
  private startLogs: boolean = false
  public restrict = true;
  public jwplayer: any = '';
  public videoData: any = { category: { title_en: '', slug: '' } };
  public lang: any = '';
  public error: any = false;
  public youtubestream_url: any = '';
  public previousUrl: any;
  private playerInitialized = false;

  isMobile = (window as any).isMobileLayoutV2();
  resClass = 'col-8'
  private player: any;

  ngAfterViewChecked() {
    if (!this.playerInitialized && this.youtubeIframe) {
      this.loadYouTubePlayer();
      this.playerInitialized = true;
    }
  }

  loadYouTubePlayer() {
    if (!this.youtubeIframe?.nativeElement) {
      console.error('YouTube iframe not found');
      return;
    }

    // this.player = new YT.Player(this.youtubeIframe.nativeElement, {
    //   videoId: this.next_video_id,
    //   events: {
    //     'onStateChange': this.onPlayerStateChange.bind(this)
    //   }
    // });
  }

  onPlayerStateChange(event: any) {
    console.log('YouTube Player Event:', event);

    switch (event.data) {
      case YT.PlayerState.PLAYING:
        console.log('ðŸŽ¬ Video Started Playing');
        break;
      case YT.PlayerState.PAUSED:
        console.log('â¸ï¸ Video Paused');
        break;
      case YT.PlayerState.ENDED:
        console.log('ðŸ Video Ended. Redirecting...');
        this.router.navigate(['/next-page']); // Redirect when video ends
        break;
      case YT.PlayerState.BUFFERING:
        console.log('â³ Video Buffering...');
        break;
      case YT.PlayerState.CUED:
        console.log('ðŸŽµ Video Ready to Play (Cued)');
        break;
      default:
        console.log('ðŸ“º Unhandled State:', event.data);
    }
    if (event.data === YT.PlayerState.ENDED) {
      console.log('Video ended. Redirecting...');
      this.router.navigate(['/watch'], { queryParams: { watch_id: this.next_video_id } });
    }
  }
  constructor(
    private meta: Meta,
    private playerservice: PlayerService,
    public user: AuthServiceService,
    private activeRoute: ActivatedRoute,
    private authService: AuthServiceService,
    private router: Router,
    public translate: TranslateService,
    private langSerivce: LanguageService,
    private titleService: Title,
    private sanitizer: DomSanitizer,
    private navigation: NavigationService
  ) {
    if (this.isMobile) {
      this.resClass = 'col-12'
    }
    this.lang = this.langSerivce.getLang();
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.previousUrl = event.url;
      });
  }

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((params) => {
      this.watch_id = params['watch_id'];
      if (!this.authService.isLoggedIn()) {
        this.fetchDataOnly();
      } else {
        this.fetchDataWithStream();
      }
    });
  }

  generateMetaTags(data: any) {
    this.meta.updateTag({
      property: 'og:image',
      content: data['cover_image'],
    })

    this.meta.updateTag({
      property: 'og:title',
      content: data['title'],
    })

    this.meta.updateTag({
      'property': 'og:description',
      content: data['description'],
    });

    this.meta.updateTag({
      property: 'al:android:url',
      content: 'mnm://live-watch?watch_id=' + this.watch_id,
    });

    this.meta.updateTag({
      property: 'al:android:package',
      content: 'com.newitventure.movienmasti',
    });

    this.meta.updateTag({
      property: 'al:android:app_nam',
      content: 'Darts Live',
    });

    this.meta.updateTag({
      property: 'og:url',
      content: data['title'],
    });

    this.meta.updateTag({
      property: 'og:type',
      content: 'website',
    });
  }

  back(): void {
    this.navigation.back();
  }

  fetchDataOnly() {
    this.playerservice.getUrl(this.watch_id).subscribe({
      next: (data) => {
        this.error = false;
        this.videoData = data;
        this.restrict = data.restrict;
        this.generateMetaTags(data)
      },
      error: (error: any) => {
        this.error = true;
      },
    });
  }

  fetchDataWithStream() {
    this.playerservice.getStreamUrl(this.watch_id).subscribe({
      next: (data) => {
        this.generateMetaTags(data)
        this.error = false;
        this.videoData = data;
        this.next_video_id = 0
        this.youtubestream_url = this.sanitizer.bypassSecurityTrustResourceUrl(
          'https://www.youtube.com/embed/' +
          this.videoData.stream_url +
          '?autoplay=1&fs=1&modestbranding=1&iv_load_policy=3&enablejsapi=1'
        );
        if (this.videoData['url_type'] == 'youtube') {
          this.addWatchHistry({ watch_duration: 1, duration: 1 });
        }

        this.titleService.setTitle(this.videoData.title);
        this.restrict = data.restrict;
        if (data.stream_url) {
          this.playVideo(data);
          this.restrict = false;
        }
      },
      error: (error: any) => {
        this.error = true;
        if (error.status == '404')
          this.router.navigate(['/']);
      },
    });
  }

  playVideo(data: any) {
    this.jwplayer = jwplayer('player').setup({
      file: data["stream_url"],
      autostart: true,
      skin: {
        url: '../../../../assets/skin.css',
        name: 'myskin',
      },
      image: data['cover_image'],
      playbackRateControls: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 2],
      aspectratio: "16:9",
      stretching: 'uniform'
    });

    this.jwplayer.removeButton('rewind');

    this.jwplayer.addButton(
      '../../../../assets/player/rewind-hover.svg',
      'Rewind by 15 sec',
      this.rewind,
      'rewind-video-button'
    );

    this.jwplayer.addButton(
      '../../../../assets/player/forward-hover.svg',
      'Forward by 15 sec',
      this.forward,
      'forward-video-button'
    );

    setTimeout(() => {
      // $('body').find('.jw-icon-rewind').hide();
    }, 900);

    this.jwplayer.on('complete', (evemt: any) => {
      const playerContainer = this.jwplayer.getContainer();
      const errorConntaianer = playerContainer.querySelector('.jw-error-msg');
      const previewContaianer = playerContainer.querySelector('.jw-preview');

      $(previewContaianer).css({
        'background-image': 'url(' + data['cover_image'] + ')',
      });

      // this.router.navigate(['/live-watch?watch_id=1074']);
      // this.router.navigate(['/watch'], { queryParams: { watch_id: this.next_video_id } });

    });


    this.jwplayer.on('play', (e: any) => {
      if (jwplayer('player').getPosition() == 0) {
        this.addWatchHistry({ watch_duration: Math.abs(Math.ceil(this.jwplayer.getPosition())), duration: Math.abs(Math.ceil(this.jwplayer.getDuration())) });
      } else {
        this.addWatchHistry({ watch_duration: Math.abs(Math.ceil(this.jwplayer.getPosition())), duration: Math.abs(Math.ceil(this.jwplayer.getDuration())) });
      }
    })

    this.jwplayer.on('setupError', (evemt: any) => {
      $('#player').css('height', ($(window).innerHeight() * 83) / 100);
      const playerContainer = this.jwplayer.getContainer();
      const errorConntaianer = playerContainer.querySelector('.jw-error-msg');

      const play = $(errorConntaianer).append(
        $(
          ' <div class="jw-icon jw-reset replay-button-error1"><svg class="jw-svg-icon jw-svg-icon-error" xmlns="http://www.w3.org/2000/svg" class="jw-svg-icon jw-svg-icon-replay1" viewBox="0 0 240 240" focusable="false"><path d="M120,41.9v-20c0-5-4-8-8-4l-44,28a5.865,5.865,0,0,0-3.3,7.6A5.943,5.943,0,0,0,68,56.8l43,29c5,4,9,1,9-4v-20a60,60,0,1,1-60,60H40a80,80,0,1,0,80-79.9Z"></path></svg></div> '
        )
      );
      $('body')
        .find('.replay-button-error1')
        .on('click', () => {
          this.fetchDataWithStream();
        });
      $('#player').css({
        'background-image': 'url(' + data['cover_image'] + ')',
        'background-size': 'cover',
      });
    });

    this.jwplayer.on('error', (event: any) => {
      const playerContainer = this.jwplayer.getContainer();
      const errorConntaianer = playerContainer.querySelector('.jw-error-msg');
      const previewContaianer = playerContainer.querySelector('.jw-preview');
      const play = $(errorConntaianer).append(
        $(
          ' <div class="jw-icon jw-reset replay-button-error1"><svg class="jw-svg-icon jw-svg-icon-error" xmlns="http://www.w3.org/2000/svg" class="jw-svg-icon jw-svg-icon-replay1" viewBox="0 0 240 240" focusable="false"><path d="M120,41.9v-20c0-5-4-8-8-4l-44,28a5.865,5.865,0,0,0-3.3,7.6A5.943,5.943,0,0,0,68,56.8l43,29c5,4,9,1,9-4v-20a60,60,0,1,1-60,60H40a80,80,0,1,0,80-79.9Z"></path></svg></div> '
        )
      );

      $('body')
        .find('.replay-button-error1')
        .on('click', () => {
          this.fetchDataWithStream();
        });
      $(previewContaianer).css({
        'background-image': 'url(' + data['cover_image'] + ')',
      });
    });

    let isSaved = true
    setInterval(() => {
      this.jwplayer.on('time', (e: any) => {
        if (isSaved) {
          // this.saveWatchVideo(e.position)
          isSaved = false;
        }
      })
      isSaved = true;
    }, 60000);
    // this.addWatchHistry({});
  }

  onLogout() {
    this.user.logout();
  }

  rewind() {
    const currentTime = jwplayer('player').getPosition();
    if (currentTime <= 15) {
      jwplayer('player').seek(0);
      return;
    }
    jwplayer('player').seek(currentTime - 15);
  }

  forward() {
    const currentTime = jwplayer('player').getPosition();
    jwplayer('player').seek(currentTime + 15);
  }


  ngOnDestroy(): void {
    if (this.user.isLoggedIn() && this.jwplayer) {
      this.addWatchHistry({ watch_duration: Math.abs(Math.ceil(this.jwplayer.getPosition())), duration: Math.abs(Math.ceil(this.jwplayer.getDuration())) })
    }
  }

  addWatchHistry(data: any) {
    let total_duration = 0;
    if (this.jwplayer) {
      total_duration = this.jwplayer.getDuration()
    }
    let tempdata = {
      item: 'video',
      video_id: this.watch_id,
      video_type: 'video',
      platform: 'web',
      is_dvr: 0,
      is_live: 0,
      charge_status: this.user.getChargeStatus(),
      ottId: this.user.getOttId(),
      insert_id: this.e5(),
      watch_duration: data.watch_duration,
      duration: data.duration,
      item_id: this.watch_id
    };
    let webdata = {
      video_id: this.watch_id,
      video_type: 'video',
      platform: 'web',
      is_dvr: 0,
      charge_status: this.user.getChargeStatus(),
      ottId: this.user.getOttId(),
      insert_id: this.e5(),
      watch_duration: data.watch_duration,
      duration: data.duration
    };

    td.addRecord(
      'user_logs',
      webdata,
      function () { },
      function () { }
    );

    this.playerservice
      .recordwatchHistory(tempdata)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => { });
  }

  e5() {
    let lut = [];
    for (var i = 0; i < 256; i++) {
      lut[i] = (i < 16 ? '0' : '') + i.toString(16);
    }

    var k = [
      'x',
      'x',
      'x',
      'x',
      '-',
      'x',
      'x',
      '-',
      '4',
      'x',
      '-',
      'y',
      'x',
      '-',
      'x',
      'x',
      'x',
      'x',
      'x',
      'x',
    ];
    var u = '',
      i = 0,
      rb = (Math.random() * 0xffffffff) | 0;
    while (i++ < 20) {
      var c = k[i - 1],
        r = rb & 0xff,
        v = c == 'x' ? r : c == 'y' ? (r & 0x3f) | 0x80 : (r & 0xf) | 0x40;
      u += c == '-' ? c : lut[v];
      rb = i % 4 == 0 ? (Math.random() * 0xffffffff) | 0 : rb >> 8;
    }
    return u;
  }
  // back(): void {
  //   this.navigation.back();
  // }

  siteLink = ''
  openLink() {
    this.siteLink = 'https://www.dartslive.com/regist/?hunt=SdMNmCVyL9I57i92DJOu'
    if (this.langSerivce.currentLang == 'jp') {
      this.siteLink = 'https://www.dartslive.com/regist/dartslivetv/ja/?hunt=SdMNmCVyL9I57i92DJOu'
    }
    window.open(this.siteLink)
  }

  isopenLink = 'pills-chefsprogram-tab';
  public OpenLink(link: any) {
    this.isopenLink = link;
  }


  isOpenTab = 'Overview'
  openTab(tab: string) {
    this.isOpenTab = tab;
  }

  @HostListener('window:resize', ['$event.target.innerWidth'])
  onResize(width: number) {
    console.log(width);
    this.isMobile = (window as any).isMobileLayoutV2();
    if (this.isMobile) {
      this.resClass = 'col-12'
    } else {
      this.resClass = 'col-8'
    }

  }
}
