import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-events',
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.css'],
    standalone: false
})
export class EventsComponent implements OnInit {
  @Input() videoData: any = {};
  columnClass: string = 'col-6';
  @Input() current_machine_id: any;
  @Input() multiLiveEvents: any;
  @Input() isOpenTab: any;
  isMobile = (window as any).isMobileLayout();
  @Output() childEvent = new EventEmitter();

  constructor(private breakpointObserver: BreakpointObserver) { }

  ngOnInit(): void {
    this.breakpointObserver.observe([
      Breakpoints.XSmall, // Phones
      Breakpoints.Small,  // Tablets
      Breakpoints.Medium, // Small Laptops
      Breakpoints.Large   // Desktops
    ]).subscribe(result => {
      if (result.breakpoints[Breakpoints.XSmall]) {
        this.columnClass = 'col-6'; // Single column for extra small devices
      } else if (result.breakpoints[Breakpoints.Small]) {
        this.columnClass = 'col-6';
      } else if (result.breakpoints[Breakpoints.Medium]) {
        this.columnClass = 'col-md-4'; // Three columns for medium devices
      } else if (result.breakpoints[Breakpoints.Large]) {
        this.columnClass = 'col-md-3'; // Four columns for large devices
      }
    });
  }

  controlLength(data: any) {
    if (data.length > 40) {
      data = data.substring(0, 40) + '...';
    }
    return data;
  }

  changeStream(data: any) {
    if (!data.is_stop)
      this.childEvent.emit(data);
  }


  updateEvents() {
    this.childEvent.emit({ update: true });
  }

  getColumnClass(): string {
    return this.columnClass;
  }


}
