<div class="v-main__wrap" style="padding: 0 25px;">
    <div data-v-459cfd84="" data-v-edfbd00c="" class="row pageBackground fill-height no-gutters">
        <div class="col-sm-12 col-12">
            <h2 data-v-459cfd84="" class="text-sm-h4 text-h6  px-8 text-center">
                特定商取引法に基づく表記
            </h2>
            <p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">&nbsp;</span></p>
            <p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">
                <strong><span
                        style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">事業者名</span></strong><span
                    style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">&nbsp;</span>
            </p>
            <p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">
                <span
                    style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">株式会社ダーツライブ&nbsp;</span>
            </p>
            <p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">&nbsp;</span></p>
            <p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">
                <strong><span
                        style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">所在地</span></strong><span
                    style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">&nbsp;</span>
            </p>
            <p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">
                <span
                    style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">〒141-0033&nbsp;</span>
            </p>
            <p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">東京都品川区西品川一丁目1-1
                    住友不動産大崎ガーデンタワー&nbsp;</span></p>
            <p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">&nbsp;</span></p>
            <p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">
                <strong><span
                        style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">代表者</span></strong><span
                    style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">&nbsp;</span>
            </p>
            <p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">代表取締役　阿部
                    東&nbsp;</span>
            </p>
            <p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">&nbsp;</span></p>
            <p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">
                <strong><span
                        style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">お問い合わせ先</span></strong><span
                    style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">&nbsp;</span>
            </p>
            <p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">
                <span
                    style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">お問い合わせフォーム：こちらからご利用ください。&nbsp;</span>
            </p>
            <p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">電話番号：0570-067-666
                    　平日10:00～12:00 13:00～17:00（土・日・祝日・年末年始を除く）&nbsp;</span></p>
            <p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">&nbsp;</span></p>
            <p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">
                <strong><span
                        style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">価格・お支払い時期・お支払い方法</span></strong><span
                    style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">&nbsp;</span>
            </p>
            <p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">
                <span
                    style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">各商品・サービスのご購入ページにおいて表示する価格・お支払い時期・お支払い方法をご参照ください。&nbsp;</span>
            </p>
            <p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">&nbsp;</span></p>
            <p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">
                <strong><span
                        style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">販売価格以外の費用</span></strong><span
                    style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">&nbsp;</span>
            </p>
            <p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">
                <span
                    style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">各商品・サービスのご購入ページにおいて表示する費用をご参照ください。&nbsp;</span>
            </p>
            <p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">
                <span
                    style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">なお、サービスをご利用頂くにあたり、電気通信回線の通信料金(インターネット接続料金を含みます)、パケット通信料等が必要となる場合は、お客様にて別途ご用意いただく必要があります。こちらの料金につきましては、お客様ご利用のインターネットプロバイダー、携帯電話会社等にお問い合わせください。&nbsp;</span>
            </p>
            <p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">&nbsp;</span></p>
            <p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">
                <strong><span
                        style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">商品の引渡時期等</span></strong><span
                    style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">&nbsp;</span>
            </p>
            <p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">
                <span
                    style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">サービスをご利用頂く場合&nbsp;</span>
            </p>
            <p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">
                <span
                    style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">代金決済手続きの完了確認後、直ちにご利用可能となります｡&nbsp;</span>
            </p>
            <p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">&nbsp;</span></p>
            <p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">
                <span
                    style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">商品をご購入頂く場合&nbsp;</span>
            </p>
            <p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">
                <span
                    style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">代金決済手続きの完了確認後、速やかに発送いたします。&nbsp;</span>
            </p>
            <p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">&nbsp;</span></p>
            <p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">
                <span
                    style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">※交通事情、天候等の諸事情、年末年始等につきましては、お時間を頂く場合がございます。&nbsp;</span>
            </p>
            <p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">&nbsp;</span></p>
            <p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">
                <strong><span
                        style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">返品・解約について</span></strong><span
                    style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">&nbsp;</span>
            </p>
            <p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">
                <span
                    style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">商品購入後、サービス提供後の返品・解約はお受けできません。なお商品・サービスに瑕疵がある場合の対応につきましては、各商品、サービスの利用規約又はご購入ページの定めに従って対応いたします。&nbsp;</span>
            </p>
            <p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">&nbsp;</span></p>
            <p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">
                <strong><span
                        style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">ソフトウェア等の動作環境</span></strong><span
                    style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">&nbsp;</span>
            </p>
            <p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">
                <span
                    style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">各商品・サービスをご利用いただくために必要な動作環境は、各商品・サービスのご購入ページに表示いたします。&nbsp;</span>
            </p>
            <p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">
                <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">&nbsp;</span></p>
            <p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">
                <strong><span
                        style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">その他</span></strong><span
                    style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">&nbsp;</span>
            </p>
            <p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;">
                <span
                    style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0);">特別な販売条件等のある商品・サービスについては、各商品・サービスのご購入ページにおいて記載いたします。&nbsp;</span>
            </p>
            <p>&nbsp;</p>
        </div>
    </div>
</div>
