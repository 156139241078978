<div class="sidebar-wrapper" *ngIf="menuList.length > 0">
  <div [ngClass]="open ? 'sidebar' : 'sidebar-active'" #sidebar>
    <div [ngClass]="open ? 'side-menu' : 'side-menu-active'">
      <div class="inner-sidebar">
        <div *ngIf="isMobile">
          <div (click)="onClose()" *ngFor="let item of menuList" class="side-item"
            [routerLink]="['/channel', item.slug]" routerLinkActive="active ">
            <a>
              <span id="menu-title"> {{ item.title }} </span>
            </a>
          </div>
        </div>
        <div *ngIf="!isMobile">
          <div *ngFor="let item of menuList" class="side-item" [routerLink]="['/channel', item.slug]"
            routerLinkActive="active ">
            <a>
              <span id="menu-title"> {{ item.title }} </span>
            </a>
          </div>
        </div>
        <div style="cursor:pointer" [ngClass]="open ? 'side-bottom ' : 'side-bottom-active'" (click)="openLink()">
          <a target="_blank">
            <div>
              <img src="../../../../assets/service-logo.svg" alt="" />
            </div>
            <span class="d-block pt-1">{{ "home.brand" | translate }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="open" (click)="onOverlayclick()" class="d-lg-none d-sm-block overlay ">
  </div>
</div>