import { Component, OnInit } from '@angular/core';
import { ChannelService } from 'src/app/services/channelservice/channel.service';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-multi-live-events',
    templateUrl: './multi-live-events.component.html',
    styleUrls: ['./multi-live-events.component.css'],
    standalone: false
})
export class MultiLiveEventsComponent implements OnInit {
  allData: any = [];

  constructor(private homeService: ChannelService) { }

  async ngOnInit(): Promise<void> {
    try {
      const response = await firstValueFrom(this.homeService.getMultiLiveEvents(''));
      this.allData = response?.data;
    } catch (error) {
      console.error('Error fetching live events:', error);
    }
  }

}
