<div class="v-main__wrap">
  <ng-container *ngIf="langSerivce.getLang() == 'en'">
    <div style="padding: 0 25px;"
      class="row pageBackground "
    >
      <div class="col-sm-12 col-12">
        <h2  class="text-sm-h4 text-h6 px-8 text-center">
          Privacy Policy
        </h2>
        <div
          data-v-34139930=""

          class="contentBackground content-card body-2 v-card v-sheet theme--light rounded-0"
        >
          <div  class="px-6">
            <p >
              We have the following privacy policy (“Privacy Policy”) to ensure
              the protection of personal information and other information
              provided by our customers (including users of our services,
              participants of our events, and other parties to any forms of
              transaction with us). Please be advised that Information provided
              by our customers will be handled in accordance with this Privacy
              Policy.
            </p>
            <p >
              In order to bring about the smooth business operations of our
              company and to ensure the provision of better services, we may
              acquire and retain Information in certain events.
            </p>
            <p >
              To secure the trust of our customers, we will handle Information
              that we may collect and/or retain, in the following manner:
            </p>
            <section >
              <h3 >1） Collection of Information</h3>
              <p >
                We collect Information fairly and legally.
              </p>
            </section>
            <section >
              <h3 >
                2） Items of Information that we collect
              </h3>
              <p >
                Information that we may collect is the following items and
                information that is necessary to achieve the purposes set forth
                in the Article 3.
              </p>
              <ol >
                <li >
                  Contact information, such as your name, address, phone, email,
                  and other similar information
                </li>
                <li >Web browsing history, access logs</li>
                <li >Record of play</li>
                <li >
                  Information about personal device including device ID
                </li>
                <li >Location information</li>
                <li >
                  Card Name, Catch Phrase, DARTSLIVE CARD ID, PIN Number
                </li>
                <li >Darts game results and statistics</li>
                <li >
                  Other Information necessary for our proper and efficient
                  operation, and anonymously processed information
                </li>
              </ol>
            </section>
            <section >
              <h3 >3） Purposes of Use of Information</h3>
              <p >
                We will use Information that we collect, only to the extent
                necessary for achieving the following purposes:
              </p>
              <ol >
                <li >
                  Responding to inquiries regarding our merchandise, sending of
                  catalogues and the like, sending of gifts or prizes, making
                  announcements on events such as game competitions, and
                  handling of requests for the repair of products;
                </li>
                <li >
                  Conducting marketing and surveys for the purpose of
                  development and improvement of our products;
                </li>
                <li >
                  Analysis of our service such as website traffic or use of
                  games;
                </li>
                <li >
                  Provision of information regarding our products and/or
                  services;
                </li>
                <li >
                  Management of scores, etc. of our customers;
                </li>
                <li >
                  Management of overviews and/or results, etc. of our events;
                </li>
                <li >
                  Registration for our system regarding our products/services;
                </li>
                <li >
                  Sending auto-response emails that enable users to confirm
                  information regarding themselves; and
                </li>
                <li >
                  Display and delivery of personalized advertisements to each
                  customer;
                </li>
                <li >
                  Other matters necessary for our proper and efficient
                  operation;
                </li>
                <li >
                  And any and all purposes incidental to any of the foregoing.
                </li>
              </ol>
              <p >
                If we are to be provided with Information of our customers in
                any event not described above, we will notify the customers in
                advance of the purpose of use of such Information.
              </p>
              <p >
                Please be advised that the name of any customer who applies for
                a gift/prize or an event may be published for the purpose of
                announcing winners or for any other purpose.
              </p>
              <p >
                Please ensure that any Information of those under the age of 16
                is only provided upon obtaining the consent of his/her parent or
                custodian.
              </p>
              <p >
                Unless otherwise permitted by laws and regulations, if it
                becomes necessary to change any of the purposes of use, we will
                obtain the consent of our customers for such use. When we obtain
                personally referable information about our customers, we will
                handle it appropriately in accordance with laws and regulations.
              </p>
            </section>
            <section >
              <h3 >
                4） Provision of Information to Third Parties
              </h3>
              <p >
                We will not provide Information of any of our customers to any
                third party without such customer’s prior consent except where:
              </p>
              <ol >
                <li >required by laws and regulations;</li>
                <li >
                  it is necessary for the protection of life, limb or property
                  and where it is difficult to obtain such customer’s consent;
                </li>
                <li >
                  it is especially necessary for improving public health or
                  promoting the sound growth of children and where it is
                  difficult to obtain such customer’s consent; or
                </li>
                <li >
                  it is necessary for cooperating with a state organ, a local
                  government, or an individual or business operator entrusted by
                  either of the former two in executing the affairs prescribed
                  by laws and regulations and where obtaining such customer’s
                  consent is likely to impede the execution of the affairs
                  concerned.
                </li>
              </ol>
            </section>
            <section >
              <h3 >5） Sharing of Information</h3>
              <p >
                We will use Information jointly with our parent companies, and
                their subsidiaries and affiliates (“Sega Sammy Companies”),
                pursuant to the provisions as set forth below.
              </p>
              <ol >
                <li >
                  Items of Information to be used jointly
                  <ol >
                    <li >
                      –Name, address, gender, birth date, name of customer’s
                      company or organization, position, phone number, email
                      address, play data, device ID, location information, and
                      access logs
                    </li>
                    <li >
                      –Other items necessary for proper and efficient operations
                    </li>
                  </ol>
                </li>
                <li >
                  Scope of the joint users
                  <ol >
                    <li >–Sega Sammy Holdings Inc.</li>
                    <li >–Other Sega Sammy Companies</li>
                  </ol>
                </li>
                <li >
                  Purpose for which the Information is used by the joint users
                  <ol >
                    <li >
                      Provision of information regarding our services such as
                      our products, events, campaigns, and questionnaires
                    </li>
                    <li >
                      Planning and development regarding our products and
                      services
                    </li>
                    <li >Marketing activities</li>
                    <li >
                      Management of the records regarding our products and/or
                      services used by our customers
                    </li>
                    <li >
                      Contacting to job applicants, decision of employment, and
                      management of applicants
                    </li>
                    <li >
                      Conducting background checks on whether our customers,
                      including related entities/individuals, belong to and/or
                      are associated with an organized crime group in order to
                      determine whether to start, continuously engage in, or
                      otherwise conduct any business transactions with such
                      customers
                    </li>
                    <li >
                      Other matters necessary for our proper and efficient
                      operations
                    </li>
                  </ol>
                </li>
                <li >
                  Contact information with respect to the management of
                  Information
                  <ol >
                    <li >Sega Sammy Holdings Inc.</li>
                    <li >Personal Information Inquiry</li>
                    <li >
                      Representative Director Haruki Satomi
                    </li>
                    <li >
                      Sumitomo Fudosan Osaki Garden Tower, 1-1-1
                      Nishi-Shinagawa, Shinagawa-ku, Tokyo 141-0033, Japan
                    </li>
                  </ol>
                </li>
              </ol>
            </section>
            <section >
              <h3 >
                6） Measures for Controlling the Security of Information and
                Holding Period
              </h3>
              <ol >
                <li >
                  We will use our best efforts to maintain the accuracy of the
                  content of Information to the extent necessary for achieving
                  the purposes of use, and will take the following security
                  control measures to prevent any leakage, loss or damage, etc.,
                  of Information.
                  <ol  type="a">
                    <li >
                      Maintenance of internal rules and regulations<br

                      />
                      We have established internal rules and regulations
                      regarding handling methods at each stage of acquisition,
                      use, storage, and disposal.
                    </li>
                    <li >
                      Organizational structure<br  />
                      We have appointed an information management officer and
                      established an appropriate management and supervisory
                      system, as well as a reporting system in case of
                      emergency, such as the occurrence of a leakage.
                    </li>
                    <li >
                      Employee Training<br  />
                      We provide regular training for our employees and
                      stipulate confidentiality in our internal rules and
                      regulations.
                    </li>
                    <li >
                      Maintenance of ledgers for personal information<br

                      />
                      We verify the status of personal information by recording
                      it in a personal information ledger.
                    </li>
                    <li >
                      Prevention of unauthorized access<br  />
                      We have installed a system to protect the information we
                      hold against unauthorized access.
                    </li>
                    <li >
                      Inspections and audits<br  />
                      We conduct periodic self-inspections and audits by the
                      auditing department.
                    </li>
                  </ol>
                </li>
                <li >
                  We will hold Information only for the period necessary for
                  achieving the purposes set forth above. In case that you
                  provide your Information to us in order to receive our
                  particular service, we will hold the information only for the
                  period that we provide the service to you (including further
                  period required by applicable law if any), and delete the
                  information promptly thereafter.
                </li>
              </ol>
            </section>
            <section >
              <h3 >7） Supervision of Entrustees</h3>
              <p >
                If we are to entrust any third party with the handling of
                Information, we will exercise necessary and appropriate
                supervision over such third party to ensure the secure
                management of Information.
              </p>
            </section>
            <section >
              <h3 >
                8） Customers’ rights and Handling of Complaints
              </h3>
              <ol >
                <li >
                  The customers who provide Information to us may request us to
                  disclose, correct, delete, suspend and/or restrict the use of,
                  and/or move Information to the extent allowable under the
                  applicable laws.
                </li>
                <li >
                  Besides the preceding paragraph, we will handle any
                  complaints, etc. regarding the handling of Information, in
                  good faith.
                </li>
              </ol>
            </section>
            <section >
              <h3 >
                9） Compliance with Laws and Ordinances
              </h3>
              <p >
                We will use our best efforts to protect Information, in
                compliance with the relevant laws and regulations.
              </p>
            </section>
            <section >
              <h3 >
                10） Public Announcement regarding the Retained Personal Data
              </h3>
              <p  class="font-weight-bold">
                1. Name of the business operator handling Information
                <br  />
                DARTSLIVE Co., Ltd. <br  />
                Representative Director Azuma Abe <br  />
                Sumitomo Fudosan Osaki Garden Tower, 1-1-1 Nishi-Shinagawa,
                Shinagawa-ku, Tokyo 141-0033, Japan
              </p>
              <p  class="font-weight-bold">
                2.Purposes of use of the retained personal data
                <br  />
                The purposes of use as set forth in Article 3 of this Privacy
                Policy
              </p>
              <p >
                3.Procedures to Meet Requests for Disclosure, etc. and Where to
                File Complaints
                <br  />
                We have a support desk for the handling of customers’ inquiries
                regarding Information, in order to meet any requests you may
                have for notification of the purposes of use of, disclosure or
                correction of, or suspension of use of your own Information out
                of the retained Information managed by our company. Please be
                advised that when making a request for disclosure, etc., you may
                be required to provide us with a copy of an official document
                such as your driver’s license or passport. In meeting the
                requests for disclosure, etc., we may request that you pay the
                actual costs thereof, including the costs for mailing, as well
                as the charges therefor. The details of the charges will be
                separately notified to you. Please make any inquiries regarding
                personal information (including any requests for disclosure,
                etc., and filing of complaints) through the “inquiry” form on
                our web page. If you have any questions regarding how to fill in
                the inquiry form on the web page, please contact:
                <br  />
                DARTSLIVE Co., Ltd., User Support <br  />
                Sumitomo Fudosan Osaki Garden Tower, 1-1-1 Nishi-Shinagawa,
                Shinagawa-ku, Tokyo 141-0033, Japan
              </p>
            </section>
            <section >
              <h3 >
                11） Announcement regarding Use of Our Website and Applications
              </h3>
              <h4 >1. Usage of Cookies, Ad IDs, etc.</h4>
              <p >
                For the purpose of improving users’ experience of our website
                and applications, we use cookies (information that is sent to
                our users’ device when they use our website and that is stored
                on the hard disk or memory) or an Ad ID (an ID generated by the
                operating system of users’ device for advertisements) to collect
                statistical data such as page views on our website or
                applications for the optimization of our website or app display.
                Cookies or Ad IDs are used for the purpose of providing better
                services, such as for the collection of access information by
                Google Analytics and Google Firebase SDK for ensuring the smooth
                operation of our web and app pages, and will not in any way
                infringe upon the privacy of users or affect the usage
                environment of users.
              </p>
              <h4 >
                2. Usage of Data Collection Modules (DCMs)
              </h4>
              <p >
                For the purpose of improving our services, we collect access
                information on our website or applications through the use of
                DCMs (Programs to collect data for access analysis, such as
                Google Analytics and Google Firebase SDK). DCM is a tool that
                collects user information through Cookies and Ad IDs. The
                methods of collecting and using the access information are
                described in each provider’s Terms of Service and Privacy
                Policy. For more details, please refer to the provider’s
                website, etc.
              </p>
              <h4 >3. Usage of Behavioral Targeting Ads</h4>
              <p >
                Our website and applications use Behavioral Targeting Ads via a
                program provided by a third party, such as a digital advertising
                company, to display ads relevant to users’ interests as an
                effective method. Using cookies and ad IDs, third party digital
                advertising companies collect informative data such as search
                keywords, browsing history, and IP addresses, to estimate the
                users’ interests, region of residence, gender, and age range,
                and then deliver ads accordingly. No personally identifiable
                information such as address, name, telephone number, or email
                address is collected. We use the following third-party digital
                advertising companies: <br  />
                (1) Google Ads <br  />
                (2) Google AdMob <br  />
                (3) Google AdSense <br  />
                (4) Yahoo! Promotion Ads
              </p>
              <h4 >■Use of Cookies</h4>
              <p >
                Customers can limit or disable the cookie function by changing
                the settings on their device’s browser (Internet browsing
                software) regarding the sending and receiving of cookies. When
                the cookie function is restricted or disabled, you may be unable
                to use some services or functions on some sites, and you may be
                restricted in your use of various Internet services.
                <br  />
                For more information on how to set up your browser to accept or
                reject cookies, please refer to the help section of your
                browser.
              </p>
              <h4 >■Use of Ad IDs</h4>
              <p >
                Customers can disable Behavioral Targeting Ads using ad IDs by
                changing the Ad ID settings on their device.
                <br  />
                Please refer to the website of the device or OS provider for
                details on how to change the Ad ID settings.
              </p>
              <h4 >
                ■Disablement (Opting out) of Behavioral Targeting Ads
              </h4>
              <p  class="mb-0">
                You may disable (opt-out of) Behavioral Targeting Advertisements
                from third party advertisers by following the steps on their
                websites, etc.
              </p>
              <ul >
                <li >
                  You can opt out of Google by going to the opt-out section of
                  Google’s Ad settings.
                </li>
                <li >
                  You can opt out of Yahoo! by going to the opt-out section of
                  the Yahoo! Promotion Ad site.
                </li>
                <li >
                  The Network Advertising Initiative’s opt-out page allows you
                  to disable the use of some (but not all) cookies by
                  third-party digital advertising companies at the same time.
                </li>
                <li >
                  If you wish to disable Behavioral Targeting for applications
                  (opt-out), please follow the instructions below:
                </li>
                <li >
                  To disable behavioral targeting (IDFA) for your iOS
                  applications,
                </li>
                <li >
                  To disable Behavioral Targeting (AAID) in Android
                  applications,
                </li>
              </ul>
              <h4 >
                ■Terms and Conditions for Behavioral Targeting Advertising
                Providers, etc.
              </h4>
              <p >
                Please refer to the Terms and Conditions for each Behavioral
                Targeting Advertising Provider (Google Privacy Policy, Yahoo!
                Japan Privacy Policy, etc.) for information on how they collect
                and use information.
                <br  />
                Customers use our website and applications at their own risk,
                and we shall not be liable for any disadvantage or damage caused
                by the use of these or other websites linked to them.
              </p>
            </section>
            <div  class="text-right">
              Revised: April 1, 2023 <br  />
              DARTSLIVE Co., Ltd.
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="langSerivce.getLang() != 'en'">
    <div
    style="padding: 0 25px;"
    class="row pageBackground "
    >
      <div class="col-sm-12 col-12">
        <h2  class="text-sm-h4 text-h6 px-8 text-center">
          プライバシーポリシー
        </h2>
        <div
          data-v-34139930=""

          class="contentBackground content-card body-2 py-3 v-card v-sheet theme--light rounded-0"
        >
          <div  class="px-6">
            <p >
              当社は、お客様（当社のサービス利用者、イベント等の参加者、その他当社との間でのあらゆる形態を含む取引の相手方を含むものとします）からご提供いただく個人情報等の保護のため、プライバシーポリシーを定めています。ご提供いただいた個人情報等は、このプライバシーポリシーに基づき取り扱わせていただきますのでご確認ください。
            </p>
            <p >
              当社は、当社の業務を円滑に行い、より良いサービスを提供するため、お客様の個人情報等を取得し保有させていただくことがございます。
              当社は、個人情報取扱事業者として、当社に対するお客様の信頼に応えるため、当社が取得し保有するお客様の個人情報等を以下のとおり取り扱うものとします。
            </p>
            <section >
              <h3 >1） 個人情報の取得</h3>
              <p >
                当社は、公正かつ適法な手段により個人情報等を取得します。
              </p>
            </section>
            <section >
              <h3 >2） 取得する情報の項目</h3>
              <p >
                当社が取得することがある情報は、以下に列挙する項目その他第3条に定める目的達成に必要な情報（本プライバシーポリシーにおいて「個人情報等」）とします。
              </p>
              <ol >
                <li >
                  お客様の所属組織名、氏名、住所、連絡先（電話番号、Eメールアドレス等）
                </li>
                <li >Webの閲覧履歴（アクセスログなど）</li>
                <li >プレイデータのログ</li>
                <li >端末のIDなど機器情報</li>
                <li >位置情報</li>
                <li >
                  カード名、通り名、ダーツライブカードID、暗証番号
                </li>
                <li >ダーツに関する成績</li>
                <li >
                  その他当社の業務の適切かつ円滑な遂行のために必要な個人情報および匿名加工情報等
                </li>
              </ol>
            </section>
            <section >
              <h3 >3） 個人情報等の利用目的</h3>
              <p >
                当社は、取得した個人情報等を、以下の目的の範囲でのみ利用いたします。
              </p>
              <ol >
                <li >
                  お問い合わせへの対応、カタログ等の資料送付、プレゼントの送付、イベントに関するお知らせ、製品の修理依頼に対する対応
                </li>
                <li >
                  当社製品の開発、改善を目的としたマーケティング及びアンケート活動
                </li>
                <li >
                  アクセス解析、ゲームの利用状況等、当社のサービスに関する分析
                </li>
                <li >当社製品やサービスに関する情報提供</li>
                <li >
                  当社のサービスを利用されるお客様の成績等の管理
                </li>
                <li >
                  当社が行うイベントの概要や結果等の管理
                </li>
                <li >
                  当社製品やサービスに関する登録の受付、サービスの提供
                </li>
                <li >
                  ユーザーが自分の情報を確認するための自動応答メール
                </li>
                <li >
                  お客様ごとにパーソナライズされた広告の表示及び配信
                </li>
                <li >その他当社の業務の適切かつ円滑な遂行</li>
                <li >前各号の利用目的に付随する目的</li>
              </ol>
              <p >
                上記以外にお客様から個人情報等のご提供を受ける場合においては、あらかじめ利用目的をお客様にお伝えいたします。
                プレゼントやイベントに応募されたお客様の氏名は、当選者発表等のために掲示する場合がありますので、あらかじめご了承ください。
                16歳未満の方の個人情報等については、必ず保護者の方のご確認を得たうえで、ご提供いただきますようお願いいたします。
                利用目的を変更する必要が生じた場合には、法令により許される場合を除き、その利用について、お客様の同意を得るものとします。
                当社は、お客様にかかる個人関連情報を取得したときは、法令に則り適正に取り扱うものとします。
              </p>
            </section>
            <section >
              <h3 >4） 個人情報等の第三者提供</h3>
              <p >
                当社は、以下に定める場合を除き、予めお客様の同意を得ることなく、個人情報等を第三者に提供しません。
              </p>
              <ol >
                <li >法令に基づく場合</li>
                <li >
                  人の生命、身体又は財産の保護のために必要がある場合であって本人の同意を得ることが困難であるとき
                </li>
                <li >
                  公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難な場合
                </li>
                <li >
                  国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
                </li>
              </ol>
            </section>
            <section >
              <h3 >5） 個人データの共同利用</h3>
              <p >
                当社、当社の親会社並びにその子会社及び関連会社（以下「セガサミーグループ」といいます）では、次の通り共同利用させていただきます。
              </p>
              <ol >
                <li >
                  共同利用する個人データの項目
                  <ol >
                    <li >
                      氏名、住所、性別、生年月日、所属する会社名・団体名、役職、電話番号、電子メールのアドレス、プレイデータログ、端末識別情報、位置情報、通信履歴
                    </li>
                    <li >
                      その他当社の業務の適切かつ円滑な遂行のために必要な個人情報
                    </li>
                  </ol>
                </li>
                <li >
                  共同利用者の範囲
                  <ol >
                    <li >
                      セガサミーホールディングス株式会社
                    </li>
                    <li >
                      その他のセガサミーグループ各社（詳細についてはこちらからご覧いただけます。）
                    </li>
                  </ol>
                </li>
                <li >
                  利用者の利用目的
                  <ol >
                    <li >
                      商品・イベント・キャンペーン・アンケート等各種ご案内
                    </li>
                    <li >商品及びサービス等の企画・開発</li>
                    <li >マーケティング活動</li>
                    <li >
                      お客様による商品及びサービス等の利用・使用の記録又はこれに関連する記録の管理
                    </li>
                    <li >
                      採用応募者への連絡、採用の判定、採用応募者管理
                    </li>
                    <li >
                      各種取引の開始、維持管理等のための反社会的勢力該当性の確認
                    </li>
                    <li >
                      その他当社の業務の適切かつ円滑な遂行
                    </li>
                  </ol>
                </li>
                <li >
                  個人データの管理に関する責任者 <br  />
                  〒141-0033 東京都品川区西品川一丁目1-1
                  住友不動産大崎ガーデンタワー　セガサミーホールディングス株式会社　個人情報相談窓口
                  代表取締役　里見　治紀
                </li>
              </ol>
            </section>
            <section >
              <h3 >
                6） 個人情報等の安全管理措置及び保有期間
              </h3>
              <ol >
                <li >
                  当社は、個人情報等の利用目的の実現のために必要な範囲内において、個人情報等を正確な内容に保つよう努め、また個人情報等の漏えい、滅失又は毀損などを防止するため下記の安全管理措置を講じます。
                  <ol >
                    <li >
                      社内規程の整備<br  />
                      取得、利用、保管、廃棄といった段階ごとの取扱方法等について社内規程を制定しています。
                    </li>
                    <li >
                      組織体制の整備<br  />
                      情報管理責任者を設置し、適切な管理監督体制と漏えい事故発生等緊急時の報告連絡体制を定めています。
                    </li>
                    <li >
                      従業者の教育<br  />
                      定期的に研修を実施するとともに、秘密保持に関する事項を就業規則等に規定しています。
                    </li>
                    <li >
                      個人情報取扱台帳の整備<br  />
                      個人情報取扱台帳への記録により、個人情報の取扱状況を確認しています。
                    </li>
                    <li >
                      不正アクセス等の防止<br  />
                      外部からの不正アクセスから保護する仕組みを導入しています。
                    </li>
                    <li >
                      定期点検・監査<br  />
                      定期的な自主点検、監査部門監査を実施しています。
                    </li>
                  </ol>
                </li>
                <li >
                  当社は、個人情報等を、利用目的の実現のために必要な期間内においてのみ保有するものとします。なお、個人情報等が、当社の特定のサービスの提供を受けるために提供されたものである場合には、当該個人情報等にかかるお客様に対する当該サービスの提供期間中（法令等により提供終了後の保有が求められる場合にはその期間を含みます）においてのみこれを保有するものとし、その後速やかに消去するものとします。
                </li>
              </ol>
            </section>
            <section >
              <h3 >7） 委託先の監督</h3>
              <p >
                当社は、個人情報等の取扱いを第三者に委託する場合は、当該個人情報等の安全管理が図られるよう、当該第三者に対し必要かつ適切な監督を行います。
              </p>
            </section>
            <section >
              <h3 >8） お客様の権利及び苦情対応</h3>
              <ol >
                <li >
                  個人情報等を提供いただいたお客様は、適用される法令により認められる範囲において、当社に対して、当該個人情報等の開示、訂正、消去、使用の停止・制限、移動等を請求することができます。
                </li>
                <li >
                  当社は、前項の他、個人情報等の取扱いに関する苦情等に誠実に対応します。
                </li>
              </ol>
            </section>
            <section >
              <h3 >9） 法令の遵守</h3>
              <p >
                当社は、個人情報の保護に関する法律その他の法令を遵守し、個人情報等の保護に努めてまいります。
              </p>
            </section>
            <section >
              <h3 >10） 保有個人データに関する公表事項</h3>
              <p >
                <span  class="font-weight-bold"
                  >1. 個人情報取扱事業者の名称
                </span>
                <br  />
                〒141-0033 東京都品川区西品川一丁目1-1
                住友不動産大崎ガーデンタワー<br  />
                株式会社ダーツライブ<br  />
                代表取締役　阿部東
              </p>
              <p >
                <span  class="font-weight-bold"
                  >2. 保有個人データの利用目的</span
                ><br  />
                本プライバシーポリシー第3条に定める利用目的
              </p>
              <p >
                3. 開示等の求めに応じる手続、苦情の申出先<br

                />
                当社は、個人情報等に関するお客様からのお問い合わせに対応するための窓口を設置し、当社が管理する保有個人データのうちお客様ご自身の個人データについて、法令の定めに従い利用目的の通知・開示・訂正・利用停止等の求めに対応させていただいております。
                開示等の求めに際し、本人確認のために運転免許証、パスポート等の公的書類のコピーをご提供いただく場合がありますので、あらかじめご了承ください。
                <br  />
                開示等の求めの対応にあたっては、郵送料等の実費及び手数料を申し受けることがあります。なお、手数料については、別途お知らせします。個人情報等に関するお客様からのお問い合わせ（開示等の求め、苦情の申出を含む。）は、当社ウェブサイトの「お問い合わせ」フォームからお願いいたします。
                お問い合わせ方法が不明の場合は、下記までお問い合わせください。<br />
                株式会社ダーツライブ　ユーザーサポート担当<br />
                〒141-0033 東京都品川区西品川一丁目1-1 住友不動産大崎ガーデンタワー
              </p>
            </section>
            <section >
              <h3 >
                11） 当社ウェブサイト及びアプリケーションのご利用にあたって
              </h3>
              <h4 >
                1. クッキー（Cookie）及び広告ＩＤ等の利用について
              </h4>
              <p >
                当社は、お客様の利便性向上、ウェブサイト又はアプリケーションの改善を目的として、これらの利用又は閲覧状況の統計的な把握、お客様への最適な画面表示等を行うために、クッキー（お客様が当社ウェブサイトを利用される際にご利用の端末に送信される情報であり、ハードディスク又はメモリーに保存されます）又は広告ＩＤ（お客様の端末に搭載されたＯＳにおいて生成される広告用のＩＤ）を利用しています。
                <br  />
                クッキー又は広告ＩＤは、ページ動作の保証、Google
                AnalyticsやGoogle Firebase
                SDK等のアクセス解析ツールによるアクセス情報収集、ならびに行動ターゲティング広告の表示・配信等の属性に応じた情報配信など、より良いサービスを提供する目的で使用するものであり、利用者の皆様のプライバシー、又はご利用環境を侵すことはありません。
              </p>
              <h4 >2. 情報収集モジュールの利用について</h4>
              <p >
                当社ウェブサイト又はアプリケーションにおいては、サービスの向上等を目的として、情報収取モジュール（Google
                AnalyticsやGoogle Firebase
                SDK等を用いたアクセス解析において用いられる情報収集のためのプログラムをいいます）を使用してアクセス情報を収集しています。情報収集モジュールは、クッキーや広告ＩＤを利用して情報を収集するものであり、アクセス情報の収集方法及び利用方法は、各提供事業者のサービス利用規約及びプライバシーポリシー等によって定められています。詳細は、各提供事業者のホームページ等をご確認ください。
              </p>
              <h4 >
                3. 行動ターゲティング広告の利用について
              </h4>
              <p >
                当社のウェブサイト及びアプリケーションでは、広告配信事業者等の第三者が提供するプログラムを利用し、お客様に、より有益な広告を表示する手法の一つとして、お客様の興味関心に関連した広告を表示する「行動ターゲティング広告」を利用しております。クッキーや広告ＩＤ等を用いて、第三者である広告配信業者が、検索キーワードや閲覧履歴、ＩＰアドレス等のインフォマティブデータを収集し、お客様の興味、居住地域、性別、年齢層の推定を行い、それに対応した広告を配信致します。
                なお、お客様の住所・氏名・電話番号・メールアドレス等の個人を特定できる情報は収集されません。
                当社で利用している第三者広告配信業者によるサービスは以下のとおりです。<br

                />
                (1) Google 広告<br  />
                (2) Google AdMob<br  />
                (3) Google AdSense<br  />
                (4) Yahoo!プロモーション広告
              </p>
              <h4 >■クッキー（Cookie）の設定について</h4>
              <p >
                お客様は、ご自身の端末のブラウザ（インターネット閲覧ソフト）において、クッキーの送受信に関する設定を変更することにより、クッキーの機能を制限することや無効とすることができます。なお、クッキーの機能を制限または無効とした場合には、サイトによってはサービスや機能が一部ご利用いただけなくなる等、インターネットにおける各種サービスの利用に制約を受ける場合がございます。
                クッキーに関してのブラウザの詳しい設定方法は、ご使用のブラウザのヘルプ等をご参照ください。
              </p>
              <h4 >■広告ＩＤの設定について</h4>
              <p >
                お客様は、ご自身の端末において、広告ＩＤの設定を変更することにより、広告ＩＤを用いた行動ターゲティング広告を無効化することができます。
                広告ＩＤの設定の変更方法は、ご使用の端末又はＯＳの提供事業者のウェブサイト等をご参照ください。
              </p>
              <h4 >
                ■行動ターゲティング広告の無効化(オプトアウト)について
              </h4>
              <p  class="mb-0">
                お客様は、行動ターゲティング広告を提供する第三者である広告業者のウェブサイト等において所定の操作を行うことにより、当該広告業者による行動ターゲティング広告を無効化（オプトアウト）することができます。
              </p>
              <ul >
                <li >
                  Googleの広告設定のオプトアウト設定より、Googleのオプトアウトができます。
                </li>
                <li >
                  Yahoo!プロモーション広告の無効化(オプトアウト)についてのページにおいて、Yahoo!のオプトアウトができます。
                </li>
                <li >
                  Network Advertising Initiative
                  のオプトアウトページでは、第三者広告配信業者の一部のクッキー(全てではありません)の使用をまとめて無効にすることができます。
                </li>
                <li >
                  アプリケーションの行動ターゲティングの無効化（オプトアウト）をご希望の場合は、下記の方法により設定してください。
                </li>
                <li >
                  iOSアプリケーションの行動ターゲティング（IDFA）の無効化
                </li>
                <li >
                  Androidアプリケーションの行動ターゲティング(AAID)の無効化
                </li>
              </ul>
              <h4 >
                ■行動ターゲティング広告提供業者の利用規約等
              </h4>
              <p >
                各行動ターゲティング広告提供業者による情報の収集方法及び利用方法については、各業者の利用規約等（Googleプライバシーポリシー、Yahoo!JAPANプライバシーポリシー等）をご覧ください。
              </p>
              <p >
                当社ウェブサイト及びアプリケーションのご利用はお客様ご自身の責任において行われるものとし、これら及びこれらからリンク設定がされている他のウェブサイト等のご利用によって発生する不利益や損害について、当社は一切責任を負いません。
              </p>
            </section>
            <div  class="text-right">
              平成17年4月1日制定<br  />
              平成28年8月15日改定<br  />
              平成28年12月1日改定<br  />
              平成30年5月7日改定<br  />
              令和2年8月24日改定<br  />
              令和3年3月23日改定<br  />
              令和3年10月19日改定<br  />
              令和4年3月24日改定<br  />
              令和５年４月１日改定<br  />
              株式会社ダーツライブ
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
