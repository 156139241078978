  <div class="show" *ngIf="!loading">
    <div
      style="display: flex; justify-content: space-between; align-items: center;">
      <h2 class="event-title">{{category.title_en}}</h2>
    </div>
    <hr/>
    <div class="container" *ngFor="let data of videos['data']"  [routerLink]="data.type === 'live' ? '/live-watch' : ['/watch']" [queryParams]="{ watch_id: data.id }">
      <div class="child2 image-container" >
        <img [src]="data.icon_img" [alt]="data.title" />
      </div>
      <div class="child3">
        <p style="font-weight: 500; margin:0;" *ngIf="data.title">
          {{ data.title }}
        </p>
        <p> {{ data.date }}</p>
      </div>
    </div>
    <div class="show" *ngIf="loading">
      loading...
    </div>
