import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { ChannelService } from 'src/app/services/channelservice/channel.service';
import { LanguageService } from 'src/app/services/language.service';
import { DataShareService } from 'src/app/utilitis/services/data-share-services';
declare var $: any;

@Component({
    selector: 'app-channel',
    templateUrl: './channel.component.html',
    styleUrls: ['./channel.component.css'],
    standalone: false
})
export class ChannelComponent implements OnInit {
  public id: any;

  public channelData$: Observable<any> = of({});
  channelData: any = { videos: { data: [] }, coupons: [] };
  public error: any = false;
  allData: any = []
  public lang: any = '';
  public total_pages: any = 0;
  nextPage: any;
  currentPosition = window.innerHeight;
  public loading: boolean = false;
  meta: any = {
    current_page: 1,
    last_page: 1,
  };
  constructor(
    private route: ActivatedRoute,
    private channelService: ChannelService,
    private service: DataShareService,
    public translate: TranslateService,
    private langSerivce: LanguageService,
    private titleService: Title
  ) {
    this.lang = this.langSerivce.getLang();
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
      this.onloadMore(this.id)
    })
  }

  @HostListener('window:scroll', ['$event.target'])
  scroll(e: any) {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
      if (this.meta.current_page <= this.total_pages) {
        this.meta.current_page++;
        this.onloadMore(this.id);
      } else {
        // this.loading = true;
      }
    }
  }

  onloadMore(id: any) {
    this.channelService
      .getChannelByPaginateV2(id, this.meta.current_page)
      .subscribe((res: any) => {
        if (res['data']['videos'].total == 0) {
          this.error = true;
          this.loading = true;
        } else {
          this.error = false;
          this.loading = true;
          this.meta = res['data']['videos'];
          this.meta.last_page = res['data']['videos'].last_page;
          this.nextPage = res['data']['videos'].next;
          if (this.allData.length && res['data']['videos'].current_page > 1) {
            res.data.videos.data.forEach((element: any) => {
              this.allData.push(element);
            });
          } else {
            this.channelData = res.data;
            this.loading = true;
            this.allData = res.data.videos.data
            this.total_pages = res['data']['videos'].last_page;
          }
        }
      });
  }
}
