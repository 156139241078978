<div class="row" style="padding: 0 25px;">
  <div class="col-sm-12 col-12">
    <div>
      <div class="archive" id="archivesection">
        <div class="row gap-0">
          <div class="title">
            <h1>{{ "home.multiLiveEvent" | translate }}</h1>
          </div>
          <div
            class="col-lg-3 col-md-6 col-sm-12 padding-0 list"
            *ngFor="let item of allData"
            app-item
            [item]="item"
            [link]="'live-watch'"
            [lang]="'eng'"
          ></div>
        </div>
      </div>
    </div>
  </div>
</div>
