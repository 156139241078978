import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Category, LiveEvent } from 'src/app/shared/models/video.model';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChannelService {
  private baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getChannelByPaginate(id: any, page: any) {
    return this.http.get(this.baseUrl + 'categories/' + id + '/paginate?per_page=17&page=' + page).pipe(
      map((data: any) => {
        let response: any = [];
        data['data'].videos.data.forEach((element: any) => {
          element.link = 'live-watch';
          if (element.type == 'video') {
            element.link = 'watch';
          }
          if (element['start_time_timestamp'] && element['type'] == 'live') {
            let date2 = new Date(element['start_time_timestamp'] * 1000);
            element['start_time'] = date2
          }
          response.push(element);
        });
        data['data']['videos']['data'] = response;
        return data;
      })
    )
  }

  getChannelByPaginateV2(id: any, page: any, per_page = 17) {
    return this.http.get(environment.apiV2Url + 'categories/' + id + '/paginate?per_page='+per_page+'&page=' + page).pipe(
      map((data: any) => {
        let response: any = [];
        data['data'].videos.data.forEach((element: any) => {
          element.link = 'live-watch';
          if (element.type == 'video') {
            element.link = 'watch';
          }
          if (element['start_time_timestamp'] && element['type'] == 'live') {
            let date2 = new Date(element['start_time_timestamp'] * 1000);
            element['start_time'] = date2
          }
          response.push(element);
        });
        data['data']['videos']['data'] = response;
        return data;
      })
    )
  }

  getChannelById(id: any) {
    return this.http.get(this.baseUrl + 'categories/' + id).pipe(
      map((data: any) => {
        let response: any = [];
        data['data'].videos.forEach((element: any) => {
          element.link = 'live-watch';
          if (element.type == 'video') {
            element.link = 'watch';
          }
          if (element['start_time_timestamp'] && element['type'] == 'live') {
            let date2 = new Date(element['start_time_timestamp'] * 1000);
            element['start_time'] = date2
          }
          response.push(element);
        });
        data['data']['videos'] = response;
        return data['data'];
      })
    );
  }

  getArchives(page: any) {
    return this.http.get(this.baseUrl + 'archives?page=' + page + '&per_page=30').pipe(
      map((data: any) => {
        data['data'].map((data: any) => {
          if (data['title'].length > 50) {
            data['title'] = data['title'].substring(0, 50) + '...';
          }
          return data;
        });
        return data;
      })
    );
  }

  getMultiLiveEvents(page: any) {
    return this.http.get(this.baseUrl + 'multi-live-events?page=' + page + '&per_page=30').pipe(
      map((data: any) => {
        data['data'].map((data: any) => {
          data['id'] = data['live_event_id']
            data['title'] = data['cards'].toString();
            data['title'] = data['title'].replace(/,/g, " VS ");
            if (data['title'].length > 50) {
              data['title'] = data['title'].substring(0, 50) + '...';
            }
          return data;
        });
        return data;
      })
    );
  }

  getLives(): Observable<LiveEvent[]> {
    return this.http.get<LiveEvent[]>(this.baseUrl + 'lives').pipe(
      map((data: any) => {
        data['data'].map((data: any) => {
          if (data['title'].length > 50) {
            data['title'] = data['title'].substring(0, 50) + '...';
          }
          if (data['start_time_timestamp']) {
            let date2 = new Date(data['start_time_timestamp'] * 1000);
            data['start_time'] = date2
          }
          return data;
        });
        return data;
      })
    );
  }

  getLive(page: any) {
    return this.http.get(this.baseUrl + 'lives?page=' + page).pipe(
      map((data: any) => {
        data['data'].map((data: any) => {
          if (data['title'].length > 50) {
            data['title'] = data['title'].substring(0, 50) + '...';
          }
          if (data['start_time_timestamp'] && data['type'] == 'live') {
            let date2 = new Date(data['start_time_timestamp'] * 1000);
            data['start_time'] = date2
          }
          return data;
        });
        return data;
      })
    );
  }


  getChildCategory(id:any): Observable<{data: Category}> {
      return this.http.get<{data: Category}>(this.baseUrl + 'categories/children/' + id)
  }
  
}
