import { Component, HostListener, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LanguageService } from './services/language.service';
import { DataShareService } from './utilitis/services/data-share-services';
import { Firestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage } from "firebase/messaging";
import { getToken } from "firebase/messaging";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  status: any;
  item$: Observable<any>;
  firestore: Firestore;
  mobilesidebar: any;
  title = 'DartsLivetv';
  public lang: any = '';
  constructor(
    private datashareService: DataShareService,
    private router: Router,
    private titleService: Title,
    private message: AngularFireMessaging,
    private meta: Meta,
    private langSerivce: LanguageService
  ) {
    this.datashareService.changeprofileStatus(false);
    this.lang = this.langSerivce.getLang();
    if (environment.production && environment.apiUrl == "https://api.live.dartslive.tv/api/v1/") {
      if (this.lang == 'en') {
        this.meta.addTags([
          {
            'data-n-head': 'description',
            name: 'description',
            content: 'Fascinating dart fans with high level matches',
          },
          {
            'data-n-head': 'og:description',
            name: 'og:description',
            content: 'Fascinating dart fans with high level matches',
          },
          {
            'data-n-head': 'ssr',
            name: 'format-detection',
            content: 'telephone=no',
          },
          {
            'data-n-head': 'ssr',
            name: 'format-detection',
            content: 'telephone=no',
          },
          {
            'data-n-head': 'ssr',
            name: 'keywords',
            content:
              'dartslive,ダーツライブ,DARTS,ダーツ,飛鏢,ダーツライブtv,dartslive tv,ダーツ 投げ方,ダーツ 試合',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'og:type',
            name: 'og:type',
            content: 'og:type',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'og:url',
            name: 'og:url',
            content: 'https://dartslive.tv/',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'og:site_name',
            name: 'og:site_name',
            content: 'DARTSLIVE TV - DARTSLIVE Official Video Site',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'og:i18n-og',
            name: 'og:locale',
            content: 'en_US',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'i18n-og-alt-ja-JP',
            name: 'og:locale:alternate',
            content: 'ja_JP',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'og:title',
            name: 'og:title',
            content: 'DARTSLIVE TV - DARTSLIVE Official Video Site',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'og:title',
            name: 'og:title',
            content: 'DARTSLIVE TV - DARTSLIVE Official Video Site',
          },
        ]);
      }
      if (this.lang == 'jp') {
        this.meta.addTags([
          {
            'data-n-head': 'description',
            name: 'description',
            content: '世界のダーツファンを魅了するハイレベルな試合を配信',
          },
          {
            'data-n-head': 'og:description',
            name: 'og:description',
            content: '世界のダーツファンを魅了するハイレベルな試合を配信',
          },
          {
            'data-n-head': 'ssr',
            name: 'format-detection',
            content: 'telephone=no',
          },
          {
            'data-n-head': 'ssr',
            name: 'format-detection',
            content: 'telephone=no',
          },
          {
            'data-n-head': 'ssr',
            name: 'keywords',
            content:
              'dartslive,ダーツライブ,DARTS,ダーツ,飛鏢,ダーツライブtv,dartslive tv,ダーツ 投げ方,ダーツ 試合',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'og:type',
            name: 'og:type',
            content: 'og:type',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'og:url',
            name: 'og:url',
            content: 'https://dartslive.tv/',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'og:site_name',
            name: 'og:site_name',
            content:
              'ダーツライブTV - DARTSLIVE(ダーツライブ)公式ダーツ動画サイト',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'og:i18n-og',
            name: 'og:locale',
            content: 'ja_JP',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'i18n-og-alt-ja-JP',
            name: 'og:locale:alternate',
            content: 'ja_JP',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'og:title',
            name: 'og:title',
            content:
              'ダーツライブTV - DARTSLIVE(ダーツライブ)公式ダーツ動画サイト',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'og:title',
            name: 'og:title',
            content:
              'ダーツライブTV - DARTSLIVE(ダーツライブ)公式ダーツ動画サイト',
          },
        ]);
      }
    }
  }
  @HostListener('window:storage')
  onStorageChange() { }

  ngOnInit(): void {
    // this.requestPermission();
    this.titleService.setTitle(this.title);
    this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.datashareService.sidebarStatus.subscribe((res) => {
      this.status = res;
    });

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/firebase-messaging-sw.js')
        .then((registration) => {
          console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch((err) => {
          console.log('Service Worker registration failed:', err);
        });
    }
    const app = initializeApp(environment.firebase);
    const messaging = getMessaging(app);
    getToken(messaging, { vapidKey: "BKBUMEPwELBwfUJ2_SKj7i-kPfcGC23bkkQuDjOtj3D0s-SJS7ltzSfRh_cTEL8WvdT6UlMXYv5JZF7ALbC6bkI" }).then((currentToken) => {
      if (currentToken) {
        console.log(currentToken)
        this.requestPermission(currentToken)
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });

    
  }

  onActivate(event: any) {
    if (event.constructor.name == '/') {
      window.scrollTo(0, 0);
    }
  }


  requestPermission(currentToken: any) {
    console.log('Requesting permission...');
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
        fetch(environment.apiUrl+'iid/topics?token='+currentToken, {
          method: 'POST'
         
        })
          .then((response) => {
            if (response.status < 200 || response.status >= 400) {
              console.log(response.body, "RES:")
            }
          })
          .catch((error) => {
            console.log(error, "error")
          });
      } else {
        console.log('not permission granted.');
      }
    })
  }


}
